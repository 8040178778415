import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, notification, Radio, Space } from 'antd'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { QuizData } from '../interfaces/QuizInterface';
import { createQuiz } from '../services/quizService';

const CreateQuiz = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const onFinish = async (values: QuizData) => {


          try {
              const res = await createQuiz(values)
              if (res.status === 201) {
                  navigate('/quiz')
                  notification.success({
                      message: 'Quiz Ajouté avec succès',
                  });
              }
          } catch (err: any) {
              notification.error({
                  message: 'Erreur interne, essayer plus tard!',
              });
          }
    };

    return (
        <>
            <div className='quizContainer'>
                <div className='quizHeader'>
                    <h2>Ajouter un nouveau {t('quiz.quiz')}:</h2>
                  
                </div>
                <Form className='quizForm' name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                    <Form.List initialValue={[{
                        labelAr: "",
                        labelFr: "",
                        response: true
                    }]} name="quiz">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <div style={{ border: '1px dashed black', padding: '50px 50px 10px 50px', marginBottom: '30px' }} >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'labelFr']}
                                                fieldKey={[fieldKey, 'labelFr']}
                                                label={t('quiz.questionFR')}
                                                rules={[{ required: true, message: 'Champ obligatoire' }]}
                                            >
                                                <Input placeholder="Quelle est votre question?" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'labelAr']}
                                                fieldKey={[fieldKey, 'labelAr']}
                                                label={t('quiz.questionAR')}
                                                rules={[{ required: true, message: 'Champ obligatoire' }]}
                                            >
                                                <Input className="rtl" />
                                            </Form.Item>

                                            <p style={{ textAlign: 'center', marginBottom: '10px' }}>Réponse correcte :</p>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'response']}
                                                fieldKey={[fieldKey, 'response']}
                                                rules={[{ required: true, message: 'Champ obligatoire' }]}

                                            >
                                                <Radio.Group >
                                                    <Radio value={true}>Oui</Radio>
                                                    <Radio value={false}>Non</Radio>
                                                </Radio.Group>

                                            </Form.Item>
                                        </div>

                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item className='btnSaveQuiz'>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        {t('quiz.addQuestion')}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ajouter Quiz
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default CreateQuiz