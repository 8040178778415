import { Button, Form, Input, notification } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import '../components/adress/createAdress.css'
import { addressEndpoint, Adress } from "../interfaces/AddressInterface"
import { createAddress } from "../services/addressService"


const CreateAdress = () => {
    const { t } = useTranslation()
    const [adress, setAdress] = useState<Adress>({
        regionFR: '',
        adressFR: '',
        regionAR: '',
        adressAR: '',
        phoneNumber: '',
        longitude: '',
        latitude: ''
    })
    const navigate = useNavigate()

    /* Get address data onChange */
    const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setAdress({ ...adress, [e.target.name]: e.target.value })
    }

    /*  Get adress data onSubmit  */
    const onFinish = async (): Promise<void> => {
        const addressData: addressEndpoint = {
            regionAr: adress.regionAR,
            regionFr: adress.regionFR,
            locationFr: adress.adressFR,
            locationAr: adress.adressAR,
            phone: adress.phoneNumber,
            longitude: adress.longitude || "",
            latitude: adress.latitude ||""
        }

        const res = await createAddress(addressData)
        if (res.status === 201) {
            window.location.pathname = "/address"
          /*   notification.success({
                message: 'Addresse Ajoutée avec succès',
            }); */
        }
        if (res.error) {
            notification.error({
                message: 'Erreur serveur interne !',
            });
        }
    };


    return (
        <div className='adressContainer'>
            <h2>Ajouter une adresse</h2>

            <Form className='contentCategory' autoComplete="off" onFinish={onFinish}>

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='regionFR' label={t('adress.regionFR')} >
                    <Input name="regionFR" onChange={handleChangeAddress} />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='regionAR' label={t('adress.regionAR')} >
                    <Input className="rtl" name="regionAR" onChange={handleChangeAddress} />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='adressFR' label={t('adress.adressFR')}>
                    <Input name="adressFR" onChange={handleChangeAddress} />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='adressAR' label={t('adress.adressAR')}>
                    <Input className="rtl" name="adressAR" onChange={handleChangeAddress} />
                </Form.Item>
                <Form.Item rules={[
                  
                    {
                        pattern: new RegExp(/^\d*\.?\d*$/g),
                        message: "Ce champ doit contenir seulement des chiffres séparés avec point (ex: 10 / 10.533) "
                    },
                ]} name='longitude' label={"Longitude"}>
                    <Input name="longitude" onChange={handleChangeAddress} />
                </Form.Item>
                <Form.Item rules={[
                    {
                        pattern: new RegExp(/^\d*\.?\d*$/g),
                        message: "Ce champ doit contenir seulement des chiffres séparés avec point (ex: 10 / 10.533) "
                    },
                ]} name='latitude' label={"Latitude"}>
                    <Input name="latitude" onChange={handleChangeAddress} />
                </Form.Item>

                <Form.Item name='phoneNumber' label={t('adress.phoneNumber')}>
                    <Input name="phoneNumber" onChange={handleChangeAddress} />
                </Form.Item>

                <Form.Item className='btnSubmit__createCat'>
                    <Button type="primary" htmlType="submit">
                        Publier
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

export default CreateAdress