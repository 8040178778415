
import { CreateCategoryInterface } from '../interfaces/categoryInterface';
import axiosInstance from '../utils/axiosInstance'


export async function createCategory(data: FormData) {
    try {
        const res = await axiosInstance(`/category/createCategory`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response.data.statusCode }
    }

}
export async function createSubCategory(data: CreateCategoryInterface['createSubApi']) {
    try {
        const res = await axiosInstance(`/category/createSubCategory`, {
            method: "POST",
            data,
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response.data.statusCode }
    }

}
export async function getMainCategroy() {
    try {
        const res = await axiosInstance(`/category/getCategories`, {
            method: "GET"
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}

export async function deleteMainCategroy(id: string) {
    try {
        const res = await axiosInstance(`/category/deleteCategoryById/${id}`, {
            method: "DELETE"
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}

export async function getCategoryById(id: string) {
    try {
        const res = await axiosInstance(`/category/getCategoryById/${id}`, {
            method: "GET"
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getSubCategoryById(id: string) {
    try {
        const res = await axiosInstance(`/category/getSubCategories/${id}`, {
            method: "GET"
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}

export async function EditMainCategory(data: FormData, id: string) {
    try {
        const res = await axiosInstance(`/category/updateCategory/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data,
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function EditSubCategory(data: CreateCategoryInterface['createSubApi'], id: string) {
    try {
        const res = await axiosInstance(`/category/updateSubCategory/${id}`, {
            method: "PATCH",
            data,
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response}
    }

}
export async function getSubCategory() {
    try {
        const res = await axiosInstance(`/category/getAllSubCategories`, {
            method: "GET"
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response}
    }

}
