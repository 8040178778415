import {
  Routes,
  Route,
} from "react-router-dom";
import CreateCategory from "./pages/CreateCategory";
import ListCategory from "./pages/ListCategory";
import CreateAdress from "./pages/CreateAdress";
import ListAdress from "./pages/ListAdress";
import SendNotification from "./pages/SendNotification";
import ListNotification from "./pages/ListNotification";
import Quiz from "./pages/Quiz";
import CreateNews from "./pages/CreateNews";
import ListNews from "./pages/ListNews";
import LoginLayoutRoute from "./utils/LoginRoute";
import Login from "./pages/Login";
import Home from "./pages/Home";
import CreatePost from "./pages/CreatePost";
import ListPost from "./pages/ListPost";
import EditPost from "./pages/EditPost";
import ListSubCategory from "./pages/ListSubCategory";
import NotFound from "./pages/NotFound";
import LogoutLayoutRoute from "./utils/LogoutRoute";
import AboutUs from "./pages/AboutUs";
import CreateQuiz from "./pages/CreateQuiz";
import Profile from "./pages/Profile";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
dayjs.locale('fr') 

function App() {
  return (
    <div className="App">

      <Routes>

        <Route element={<LoginLayoutRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/create-category" element={<CreateCategory />} />
          <Route path="/category" element={<ListCategory />} />
          <Route path="/create-address" element={<CreateAdress />} />
          <Route path="/address" element={<ListAdress />} />
          <Route path="/send-notification" element={<SendNotification />} />
          <Route path="/notification" element={<ListNotification />} />
          <Route path="/add-news" element={<CreateNews />} />
          <Route path="/news" element={<ListNews />} />
          <Route path="/create-post" element={<CreatePost />} />
          <Route path="/post" element={<ListPost />} />
          <Route path="/post/:id" element={<EditPost />} />
          <Route path="/subCategory/:id" element={<ListSubCategory />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/create-quiz" element={<CreateQuiz />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/account" element={<Profile />} />
        </Route>

        <Route element={<LogoutLayoutRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>

    </div>
  );
}

export default App;