import { useTranslation } from "react-i18next"
import { Table, Modal, TablePaginationConfig, ConfigProvider, Empty } from 'antd';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ColumnsType } from 'antd/es/table';
import UpdateCatModal from "../components/category/listCategory/UpdateCatModal";
import { deleteMainCategroy, EditMainCategory, getCategoryById, getMainCategroy } from "../services/categoryService";
import { notification } from "antd";
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom";
import { Category, CategoryInformationInterface, PaginationTable } from "../interfaces/categoryInterface";


const ListCategory = () => {


  const [category, setCategory] = useState<CategoryInformationInterface['category']>({})
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState<boolean>(false);
  const [paginationTable, setPaginationTable] = useState<PaginationTable>({
    current: 1,
    pageSize: 10,
  })
  const navigate = useNavigate()
  const [listParentCategory, setListParentCategory] = useState()
  const [categoryId, setCategoryId] = useState<string>()
  const [updateCatValues, setUpdateCatValues] = useState<any>()
  const [iconPreview, setIconPreview] = useState<string>()
  const [iconUpdate, setIconUpdate] = useState<string | File>()

  const deleteCategoryHandler = (record: CategoryInformationInterface['category']) => {
    setCategoryId(record.id)
    setIsModalDeleteVisible(true);

  }
  const editCategoryHandler = async (record: CategoryInformationInterface['category']) => {
    if (record.id) {
      setCategoryId(record.id)
      const res = await getCategoryById(record.id)
      if (res) {
        setUpdateCatValues(res)
        if (res.data) {

          setIsModalEditVisible(true);
          setCategory(res.data)
        }
        if (res.error) {
          notification.error({
            message: 'Erreur interne, essayer plus tard!',
          });
        }

      }
    }

  }

  const columns: ColumnsType<Category> = [

    {
      title: 'Catégories FR',
      dataIndex: 'titleFr',
      key: 'titleFr',
    },
    {
      title: 'Catégories AR',
      dataIndex: 'titleAr',
      key: 'titleAr',
    },
    {
      title: 'Date de création',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record: string) => {
        return (
          <p>{dayjs(record).format('DD/MM/YYYY')}</p>
        )
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: CategoryInformationInterface['category']) => {
        return (
          <div className='actions__category'>
            <DeleteOutlined style={{ marginRight: "20px", color: 'red' }} onClick={() => deleteCategoryHandler(record)} />
            <EditOutlined style={{ marginRight: "20px", color: 'green' }} onClick={() => editCategoryHandler(record)} />
            {record.hasChild && <span style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => navigate(`/subCategory/${record.id}`)}>Voir sous catégories</span>}
          </div>
        )
      }
    },
  ];

  /* Delete Modal */
  const handleDeleteOk = async (): Promise<void> => {
    let res;

    if (categoryId) res = await deleteMainCategroy(categoryId)
    if (res?.status) window.location.reload();
    if (res?.error) {
      notification.error({
        message: "Erreur Interne, Essayer plus tard",
      });
    }
  };

  const handleDeleteCancel = (): void => {
    setIsModalDeleteVisible(false);

  };

  /* Edit Modal */

  const handleEditOk = async (): Promise<void> => {
    setIsModalEditVisible(false);

    let res;
    /* Category main */
    if (!updateCatValues.data.parentId) {
      let formDataCategory = new FormData();
      if (category?.id && category?.icon && category?.titleAr && category?.titleFr && category?.primaryColor && category?.secondaryColor) {
        formDataCategory.append(`titleAr`, category?.titleAr);
        formDataCategory.append(`titleFr`, category?.titleFr);
        if (iconUpdate) formDataCategory.append(`icon`, iconUpdate);
        formDataCategory.append(`primaryColor`, category?.primaryColor);
        formDataCategory.append(`secondaryColor`, category?.secondaryColor);

        res = await EditMainCategory(formDataCategory, category?.id)
        if (res.status === 200) window.location.reload()
        if (res.error) {
          notification.error({
            message: 'Erreur interne, essayer plus tard!',
          });
        }
      }

    }

  };

  const handleEditCancel = (): void => {
    setCategoryId("")
    setUpdateCatValues("")
    setCategory({})
    setIconPreview("")
    setIconUpdate("")
    setIsModalEditVisible(false);
  };


  useEffect(() => {
    async function getMainCategories(): Promise<void> {
      let res = await getMainCategroy()
      if (res.data) setListParentCategory(res.data)
      if (res.error) {
        notification.error({
          message: 'Erreur interne, essayer plus tard!',
        });
      }
    }
    getMainCategories()
  }, [])


  /* Get category data */
  const handleChangeCategory = (e: any): void => {
    if (e.target.name === "icon") {
      setCategory({ ...category, [e.target.name]: e.target.files[0] })
      setIconPreview(URL.createObjectURL(e.target.files[0]))
      setIconUpdate(e.target.files[0])
    } else {
      setCategory({ ...category, [e.target.name]: e.target.value })
    }
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const page = {
      current: Number(pagination.current),
      pageSize: Number(pagination.pageSize)
    }
    setPaginationTable(page)
  
  };

  const handleChangeSelect = (value: string): void => {

    setCategory({ ...category, parentId: value })
  }



  const { t } = useTranslation()
  return (
    <div className='listCategory'>
      {listParentCategory && <>
        <h2>{t('listCategory.title')}</h2>
        <ConfigProvider renderEmpty={() => <Empty description="Aucun résultat n’est trouvé"/>}>
        <Table<Category> onChange={handleTableChange} pagination={paginationTable} columns={columns} dataSource={listParentCategory} />
        </ConfigProvider>
        <div className='deleteCategoryModal'>
          <Modal title="Supprimer la catégorie" visible={isModalDeleteVisible} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
            <p>Voulez-vous vraiment supprimer cette catégorie?</p>
            <p><b>Note:</b> Si vous effacez cette catégorie toutes les autres sous catégories seront effacez définitivement</p>
          </Modal>
        </div>

        <UpdateCatModal iconPreview={iconPreview} updateCatValues={updateCatValues} handleChangeSelect={handleChangeSelect} handleChangeCategory={handleChangeCategory} handleEditCancel={handleEditCancel} handleEditOk={handleEditOk} isModalEditVisible={isModalEditVisible} />
      </>}

    </div>

  )
}

export default ListCategory