import { Button, Form, Input } from 'antd'
import TextArea from 'rc-textarea'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import '../components/notification/notification.css'
import { NotificationTable } from '../interfaces/NotificationInterface'
import { sendPushNotification } from '../services/notificaionService'
import { notification as notifAntd } from 'antd'
import { useLocation } from 'react-router-dom'
import { News } from '../interfaces/NewsInterface'
const SendNotification = () => {

    const { t } = useTranslation()
    const {state} = useLocation();
    const [notification, setNotification] = useState<News>({
        title: '',
        contentFr: '',
        contentAr: ''
    })

    useEffect(() => {
        if(state){
            setNotification({title:state.title, contentFr:state.data.contentFr, contentAr:state.data.contentAr})
        }
    },[])

    const navigate = useNavigate()
    
    /* Get notif data */
    const handleChangeNotif = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>): void => {
        setNotification({ ...notification, [e.target.name]: e.target.value })
    }

    /*  Get notif data  */
    const onFinish = async () => {
        console.log('nottiff', notification)
        const notif = {
            title: notification.title,
            data: { contentFr: notification.contentFr, contentAr: notification.contentAr, },
            type: "information"
        }
        const res = await sendPushNotification(notif)
        if (res.data) {
            window.location.pathname = "/notification"
            /* navigate('/notification')
            notifAntd.success({
                message: 'Notification Envoyée avec succès',
            }); */
        }
        if (res.error) {
            notifAntd.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }
    };


    /* const { title, data } = state; */

    return (
        <div className='notification'>
            <h2>Envoyer une notification</h2>
            <p>La notification sera envoyée à chaque utilisateur de l'application.</p>
            <Form className='contentCategory contentNotif' initialValues={state && {title:state.title, contentAr:state.data.contentAr, contentFr:state.data.contentFr}}  autoComplete="off" onFinish={onFinish}>

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='title' label={t('notification.title')} >
                    <Input name="title"  onChange={handleChangeNotif} />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='contentFr' label="Contenu FR">
                    <TextArea rows={4} className='textAreNotif' name="contentFr" onChange={handleChangeNotif} />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='contentAr' label="Contenu AR">
                    <TextArea rows={4} className='textAreNotif' name="contentAr" onChange={handleChangeNotif} />
                </Form.Item>

                <Form.Item className='btnSubmit__createCat'>
                    <Button type="primary" htmlType="submit">
                        {t('send')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default SendNotification