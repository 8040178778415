import axiosInstance from '../utils/axiosInstance'

export async function getStatistics() {

    try {
        const res = await axiosInstance(`/user/getStatisticts`, {
            method: "GET",
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}

export async function getListQuiz() {

    try {
        const res = await axiosInstance(`/quiz/getQuizzes`, {
            method: "GET",
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getQuizstatistics(id:string) {

    try {
        const res = await axiosInstance(`/quiz/getStatisticts/${id}`, {
            method: "GET",
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}