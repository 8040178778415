
import { notification, Select } from 'antd';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { getQuizstatistics } from '../../services/homeService';


const { Option } = Select;

const QuizChart = ({ listQuiz, quizStatistics, setQuizStatistics, formatQuizStatistics }: any) => {

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#DB7093', "#CC0022", "#8468D1"];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" >
                {`${(percent * 100).toFixed(0)}%`}

            </text>
        );
    };

    const CustomTooltip = ({ payload }: any) => {
        return (
            <div className='quizChart__tooltip'>
                <b>{payload?.[0]?.payload?.name}: </b>
                {payload?.[0]?.payload?.femme}F / {payload?.[0]?.payload?.homme}H ({payload?.[0]?.payload?.count})
            </div>

        )
    }

    const handleChangeSelect = (val: string) => {
        async function fetchQuizstatistics(id: string) {
            const res = await getQuizstatistics(id)
            if (res.data) setQuizStatistics(res.data)
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }
        fetchQuizstatistics(val)
    }

    return (
        <>
      <div className='quizChart'>
                <h2 className='chartTitle'>Résultat du quiz</h2>
                <div className='chartQuiz__container'>
                    {listQuiz.length !== 0 && <Select className='filterQuiz' defaultValue={listQuiz[listQuiz.length - 1].id} onChange={handleChangeSelect} >
                        {listQuiz.map((quiz: any) => {
                            return (
                                <Option value={quiz.id}>{quiz.title}</Option>
                            )
                        })}
                    </Select>}

                    {formatQuizStatistics.length !== 0 && <PieChart className='pieChart' width={400} height={400}>
                        <Pie
                            data={formatQuizStatistics}
                            cx={200}
                            cy={200}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={150}
                            fill="#8884d8"
                            /*  animationDuration={1000} */
                            isAnimationActive={false}
                            dataKey="count"
                        >
                            {formatQuizStatistics.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                         <Tooltip content={<CustomTooltip />} />
                        <Legend layout="vertical" verticalAlign='middle' align="right" />

                    </PieChart>}


                </div>
                {formatQuizStatistics.length === 0 && <p className='emptyQuizChart'>Il n'y a pas des réponses sur ce quiz</p>}
            </div>


        </>

    )
}

export default QuizChart