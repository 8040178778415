import { CloseOutlined } from "@ant-design/icons"
import { Button, Input, Modal, Form } from "antd"
import TextArea from "rc-textarea"
import { useTranslation } from "react-i18next"
import { PropsUpdateNewsModal } from "../../interfaces/NewsInterface"


const UpdateNewsModal: React.FC<PropsUpdateNewsModal> = ({ newsFrom, news, handleChangeNews, handleEditCancel, handleEditOk, isModalEditVisible }) => {
    const { t } = useTranslation()

    return (
        <div className='editCategoryModal'>
            {news && <Modal closeIcon={<CloseOutlined onClick={handleEditCancel} />} footer={[
                <Button key="back" onClick={handleEditCancel}>
                    Annuler
                </Button>,
                <Button key="submit" type="primary" onClick={handleEditOk}>
                    Modifier
                </Button>
               
            ]} title="Modifier l'actualité" visible={isModalEditVisible} >
                <Form initialValues={{
                    title: news.title,
                    contentFr: news.data.contentFr,
                    contentAr: news.data.contentAr
                }} className='contentCategory' autoComplete="off">
               
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Champ obligatoire',
                        },
                    ]} name='title' label="Titre">
                        <Input name="title" onChange={handleChangeNews} />
                    </Form.Item>

                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Champ obligatoire',
                        },
                    ]} name='contentFr' label={t('news.bodyFR')}>
                        <TextArea name="contentFr" onChange={handleChangeNews} />
                    </Form.Item>

                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Champ obligatoire',
                        },
                    ]} name='contentAr' label={t('news.bodyAR')}>
                        <TextArea className="rtl" name="contentAr" onChange={handleChangeNews} />
                    </Form.Item>

                </Form>
            </Modal>}
        </div>
    )
}

export default UpdateNewsModal