import { Table, Modal, TablePaginationConfig, notification, Select, ConfigProvider, Empty } from 'antd';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from "react-router-dom";
import { deleteContent, getPosts } from "../services/postService";
import { getMainCategroy, getSubCategoryById } from "../services/categoryService";
import { CategoryInformationInterface } from "../interfaces/categoryInterface";

const { Option } = Select;
const ListPost = () => {

  const columns: ColumnsType<{ id: string, category: { titleAr: string, titleFr: string } }> = [

    /* {
      title: 'Catégorie Pére',
      dataIndex: 'id',
      key: 'id',
    }, */
    {
      title: 'Sous Catégorie',
      dataIndex: 'category',
      key: 'category',
      render: (record: { titleFr: string, titleAr: string }) => {
        return (
          <div className=''>
            {record.titleFr} / {record.titleAr}
          </div>
        )
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: { id: string }) => {
        return (
          <div className='actions__category'>
            <DeleteOutlined style={{ marginRight: "20px", color: 'red' }} onClick={() => deleteCategoryHandler(record)} />
            <EditOutlined style={{ marginRight: "20px", color: 'green' }} onClick={() => navigate(`/post/${record.id}`)} />
          </div>
        )
      }
    }
  ];
  const [postId, setPostId] = useState<string>()

  const deleteCategoryHandler = (record: { id: string }) => {
    setPostId(record.id)
    setIsModalDeleteVisible(true);
  }

  const [post, setPost] = useState<{ id: string, category: { titleAr: string, titleFr: string } }[]>([])
  const [listParentCategory, setListParentCategory] = useState<CategoryInformationInterface['category'][]>([])
  const navigate = useNavigate();
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  })

  /* Delete Modal */

  const handleDeleteOk = async (): Promise<void> => {

    setIsModalDeleteVisible(false);

    if (postId) {
      const res = await deleteContent(postId)
      if (res.status === 200) window.location.reload()
      if (res.error) notification.error({
        message: 'Erreur interne, essayer plus tard!',
      });
    }

    setPostId("")
  };

  const handleDeleteCancel = (): void => {
    setIsModalDeleteVisible(false);
    setPostId("")
  };


  const handleTableChange = (pagination: TablePaginationConfig): void => {
    setPaginationTable(pagination)
    /* Here pagination get the other data */
  };

  useEffect(() => {
    async function fetchPosts(): Promise<void> {

      const res = await getPosts()
      if (res.data) {
        setPost(res.data)
      }
      if (res.error) notification.error({
        message: 'Erreur interne, essayer plus tard!',
      });
    }

    async function getMainCategories(): Promise<void> {
      let res = await getMainCategroy()
      if (res.data) setListParentCategory(res.data)
      if (res.error) {
        notification.error({
          message: 'Erreur interne, essayer plus tard!',
        });
      }
    }

    getMainCategories()
    fetchPosts()
  }, [])

  const handleChangeMainCategory = async (val: string) => {
    if (val) {
      const res = await getSubCategoryById(val)
      if (res.data) {
        const posts = res.data
        let dataPost = await posts.filter((data: any) => data.contents.length !== 0)
        await dataPost.forEach((post: any) => {
          post["category"] = { titleFr: post.titleFr, titleAr: post.titleAr, };
          post.id = post.contents[0].id
        })
        console.log('post', posts)
        setPost(dataPost)
      }
      if (res.error) notification.error({
        message: 'Erreur interne, essayer plus tard!',
      });
    }
    if (!val) {

      const res = await getPosts()
      if (res.data) {
        setPost(res.data)
      }
      if (res.error) notification.error({
        message: 'Erreur interne, essayer plus tard!',
      });

    }
  }

  return (
    <div className='listPost'>
      <div className='listPost__header'>
        <h2>Liste des publications</h2>
        {listParentCategory &&
          <Select className='selectCategoryPere' onChange={handleChangeMainCategory} placeholder="Choisir une catégorie père" >
            <Option value="">------------- none --------------</Option>
            {listParentCategory.map((item: any, index: number) => {
              return (
                <Option key={index} value={item.id}>{item.titleFr}</Option>
              )
            })}
          </Select>
        }
      </div>
      <ConfigProvider renderEmpty={() => <Empty description="Aucun résultat n’est trouvé"/>}>
      <Table<{ id: string, category: { titleAr: string, titleFr: string } }>  onChange={handleTableChange} pagination={paginationTable} columns={columns} dataSource={post} />
      </ConfigProvider>
      <div className='deleteCategoryModal'>
        <Modal title="Supprimer le post" visible={isModalDeleteVisible} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
          <p>Voulez-vous vraiment supprimer cette publication?</p>
        </Modal>
      </div>

    </div>

  )
}

export default ListPost