import { useTranslation } from "react-i18next"
import './categoryInformation.css'
import {
    Form,
    Input,
    Select
} from 'antd';
import { Fragment } from "react";
import { Props } from "../../../interfaces/categoryInterface";



const { Option } = Select;


const CategoryInformation: React.FC<Props> = ({ listParentCategory, showData, handleChangeCategory, handleChangeSelect }) => {

    const { t } = useTranslation();

    return (
        <div className='create__category'>
            <h2>{t('createCategory.title')}</h2>
            <div className='createCat__content'>

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='titleFr' label={t('createCategory.inputs.titleFR')} >
                    <Input name="titleFr" onChange={handleChangeCategory} />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='titleAr' label={t('createCategory.inputs.titleAR')}>
                    <Input className="rtl" name="titleAr" onChange={handleChangeCategory} />
                </Form.Item>
                <Form.Item name='parent' label={t('createCategory.inputs.parentCategory')}>
                    {listParentCategory && <Select defaultValue='' onChange={handleChangeSelect}>
                        <Option value=''>--- none ---</Option>
                        {listParentCategory.map((parent: any) => {
                            return (
                                <Option value={parent.id}>{parent.titleFr}</Option>
                            )
                        })}
                    </Select>}

                </Form.Item>

                {showData &&
                    <Fragment>

                        <Form.Item rules={[
                            {
                                required: true,
                                message: 'Champ obligatoire',
                            },
                        ]} name='icon' label={t('createCategory.inputs.iconCat')}>
                            <Input name="icon" onChange={handleChangeCategory} type='file' accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp" />
                        </Form.Item>
                        <Form.Item rules={[
                            {
                                required: true,
                                message: 'Champ obligatoire',
                            },
                        ]} name='primaryColor' className='paletteColor__Category' label={t('createCategory.inputs.primaryColor')}>
                            <Input name="primaryColor" onChange={handleChangeCategory} type='color' />
                        </Form.Item>
                        <Form.Item rules={[
                            {
                                required: true,
                                message: 'Champ obligatoire',
                            },
                        ]} name='secondaryColor' className='paletteColor__Category' label={t('createCategory.inputs.secColor')}>
                            <Input name="secondaryColor" onChange={handleChangeCategory} type='color' />
                        </Form.Item>

                    </Fragment>}


            </div>
        </div>
    )
}

export default CategoryInformation