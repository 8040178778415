
import { ConfigProvider, DatePicker, Empty, Input, Table, TablePaginationConfig } from 'antd';
import { useEffect, useState } from "react";
import { ColumnsType } from 'antd/es/table';
import { Notification } from "../interfaces/NotificationInterface";
import { getHistoryNotification } from '../services/notificaionService';
import { notification as notifAntd } from 'antd';
import dayjs from 'dayjs';
import { SendOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';


const ListNotification = () => {

    const navigate = useNavigate()

    const columns: ColumnsType<Notification> = [

        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (record: string) => {
                return (
                    <p>{dayjs(record).format('DD/MM/YYYY')}</p>
                )
            }
        },
        {
            title: 'Titre',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Contenu Fr',
            dataIndex: 'data',
            key: 'data',
            render: (record: { contentFr: string }) => {
                return (
                    <p>{record.contentFr}</p>
                )
            }
        },
        {
            title: 'Contenu Ar',
            dataIndex: 'data',
            key: 'data',
            render: (record: { contentAr: string }) => {
                return (
                    <p>{record.contentAr}</p>
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: { title: string, data: { content: string } }) => {
                return (
                    <div className='actions__category'>
                        <SendOutlined style={{ marginRight: "20px", color: 'green' }} onClick={() => resendNotification(record)} />

                    </div>
                )
            }
        }
    ];

    const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    })

    const [notifData, setNotifData] = useState<Notification[]>([])
    const [filtredNotif, setFiltrerdNotif] = useState<Notification[]>([])
    const [filterField, setFilterField] = useState({
        title: "",
        date: ""
    })
    const handleTableChange = (pagination: TablePaginationConfig): void => {

        setPaginationTable(pagination)
        /* Here pagination get the other data */
    };
    const resendNotification = (data: { title: string, data: { content: string } }): void => {

        navigate('/send-notification', { state: data });
    };

    useEffect(() => {
        async function getNotifications(): Promise<void> {
            const res = await getHistoryNotification("information")
            if (res.data) {
                setNotifData(res.data)
                setFiltrerdNotif(res.data)
            }
            if (res.error) notifAntd.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }

        getNotifications()
    }, [])



    const handleChangeDatePicker = (val: any) => {

        if (val) {
            setFilterField({ ...filterField, date: dayjs(val).format('DD/MM/YYYY') })
        } else {
            setFilterField({ ...filterField, date: "" })
        }

    }

    const handleChangeFilterTitle = (e: any) => {

        if (e.target.value) {
            setFilterField({ ...filterField, title: e.target.value.toLowerCase() })
        } else {
            setFilterField({ ...filterField, title: "" })
        }


    }

    /* Filter data */
    useEffect(() => {
        if (filterField) {
            if (filterField.title && filterField.date) {
                const filtredNotifData = notifData.filter((notif: { title: string, createdAt: string }) => notif.title.toLowerCase().startsWith(filterField.title.toLowerCase()) && dayjs(notif.createdAt).format('DD/MM/YYYY') === filterField.date)

                setFiltrerdNotif(filtredNotifData)
            }
            if (filterField.title && !filterField.date) {
                const filtredNotifData = notifData.filter((notif: { title: string, createdAt: string }) => notif.title.toLowerCase().startsWith(filterField.title.toLowerCase()))

                setFiltrerdNotif(filtredNotifData)
            }
            if (!filterField.title && filterField.date) {
                const filtredNotifData = notifData.filter((notif: { title: string, createdAt: string }) => dayjs(notif.createdAt).format('DD/MM/YYYY') === filterField.date)

                setFiltrerdNotif(filtredNotifData)
            }
            if (!filterField.title && !filterField.date) {
                setFiltrerdNotif(notifData)
            }

        }
    }, [filterField])

    return (
        <div className='ListNotification'>

            <div className='headerInfo'>
                <h2>Liste des notifications envoyées</h2>
                <div className='filterFields'>
                    <DatePicker placeholder='Choisir une date' onChange={handleChangeDatePicker} />
                    <Input type='text' onChange={handleChangeFilterTitle} placeholder="Titre de l'information" />
                </div>
            </div>
            <ConfigProvider renderEmpty={() => <Empty description="Aucun résultat n’est trouvé" />}>
                <Table<Notification> onChange={handleTableChange} pagination={paginationTable} columns={columns} dataSource={filtredNotif} />
            </ConfigProvider>
        </div>
    )
}

export default ListNotification