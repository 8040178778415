import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Badge, Button, Space } from 'antd'
import { useState } from 'react'

import ReactQuill from 'react-quill'
import { PropsInterface } from '../../interfaces/PostInterface'



const ImageInputs: React.FC<PropsInterface> = ({ data, num, fieldKey, restField }) => {

 
    const [showPreviewFR, setShowPreviewFR] = useState(true)
    const [showPreviewAR, setShowPreviewAR] = useState(true)

    const handleChangeImgFR = () => {
        setShowPreviewFR(false)
    }
    const handleChangeImgAR = () => {
        setShowPreviewAR(false)
    }


    return (
        <div className='containerPost'>
            <div className="infoContent__file">

                {showPreviewFR && data && data[fieldKey] && data[fieldKey].fileFR && <img  alt="" className='imgPreviewPost' src={data[fieldKey].fileFR} />}
                <Form.Item getValueFromEvent={e => e.target.files[0]}
                    getValueProps={e => e} name={[num, 'fileFR']} {...restField}
                    fieldKey={[fieldKey, 'fileFR']} label='Upload image FR' >
                    <Input name='imageFR' type='file' accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp" onChange={handleChangeImgFR} />
                </Form.Item>

                {showPreviewAR && data && data[fieldKey] && data[fieldKey].fileAR && <img  alt="" className='imgPreviewPost' src={data[fieldKey].fileAR} />}
                <Form.Item getValueFromEvent={e => e.target.files[0]}
                    getValueProps={e => e} name={[num, 'fileAR']} {...restField}
                    fieldKey={[fieldKey, 'file']} label="Upload image AR" >
                    <Input name='imageAR' type='file' accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp" onChange={handleChangeImgAR} />
                </Form.Item>
            </div>


            <Badge.Ribbon text="FR" color="red">
                <div className='intoContent__fr'>

                    <Form.Item {...restField}
                        name={[num, 'contentFR']}
                        fieldKey={[fieldKey, 'contentFR']}
                        getValueFromEvent={val => val}
                    /*  getValueProps={e => e} */
                    >
                        {/*  EditorHTML  */}

                        <ReactQuill className="shadow-sm"
                            theme="snow"
                            style={{
                                height: 350,
                                width: '98%',
                                marginTop: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: 'white'
                            }}

                            modules={{
                                toolbar: [
                                    /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                  /*   [{ 'header': [4] }], */
                                  
                                    ['bold', 'italic', 'underline'],
                                    /*  [{ 'color': [] }, { 'background': [] }], */
                                    [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                    /* [{ 'align': ['center'] }], */
                                    [{'script': 'sub'}, {'script': 'super'}],
                                    ['clean']


                                ],
                            }}
                            formats={[
                                'header', 'font', 'size',
                                'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                'script', 'sub', 'super',
                                'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                            ]}

                        />
                    </Form.Item>

                    <b>Ajouter un texte important :</b>

                    <div className='importantTxt'>
                        <Form.List name={[num, "importantTxtFR"]}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'block' }} align="baseline">
                                            <div style={{ display: 'flex', alignItems: "baseline", justifyContent: 'space-between' }} >
                                                <Form.Item {...restField}
                                                    name={[name]}
                                                    fieldKey={[fieldKey]}
                                                    getValueFromEvent={val => val}
                                                /* getValueProps={e => e} */
                                                >
                                                    {/*  EditorHTML  */}

                                                    <ReactQuill className="shadow-sm"
                                                        theme="snow"
                                                        style={{
                                                            height: 200,
                                                            width: '98%',
                                                            marginTop: '1rem',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: 'white'
                                                        }}

                                                        modules={{
                                                            toolbar: [
                                                                /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                                              /*   [{ 'header': [4] }], */
                                                                ['bold', 'italic', 'underline'],
                                                                /*  [{ 'color': [] }, { 'background': [] }], */
                                                                [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                                                /* [{ 'align': ['center'] }], */
                                                                [{'script': 'sub'}, {'script': 'super'}],
                                                                ['clean']


                                                            ],
                                                        }}
                                                        formats={[
                                                            'header', 'font', 'size',
                                                            'script', 'sub', 'super',
                                                            'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                                            'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                                                        ]}

                                                    />
                                                </Form.Item>

                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </div>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add("")} block icon={<PlusOutlined />}>
                                            Ajouter un texte important
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>



                </div>
            </Badge.Ribbon>

            <Badge.Ribbon text="AR" color="green">
                <div className='intoContent__fr intoContent__ar'>
                    <Form.Item {...restField}
                        name={[num, 'contentAR']}
                        fieldKey={[fieldKey, 'contentAR']}
                        getValueFromEvent={val => val}
                    /* getValueProps={e => e} */
                    >
                        {/*  EditorHTML  */}

                        <ReactQuill className="shadow-sm"
                            theme="snow"
                            style={{
                                height: 350,
                                width: '98%',
                                marginTop: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: 'white'
                            }}

                            modules={{
                                toolbar: [
                                    /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                   /*  [{ 'header': [4] }], */
                                    ['bold', 'italic', 'underline'],
                                    /*  [{ 'color': [] }, { 'background': [] }], */
                                    [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                    [{'script': 'sub'}, {'script': 'super'}],
                                    /* [{ 'align': ['center'] }], */
                                    ['clean']


                                ],
                            }}
                            formats={[
                                'header', 'font', 'size',
                                'script', 'sub', 'super',
                                'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                            ]}

                        />
                    </Form.Item>
                    <b>Ajouter un texte important :</b>

                    <div className='importantTxt'>
                        <Form.List name={[num, "importantTxtAR"]}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'block' }} align="baseline">
                                            <div style={{ display: 'flex', alignItems: "baseline", justifyContent: 'space-between' }} >
                                                <Form.Item {...restField}
                                                    name={[name]}
                                                    fieldKey={[fieldKey]}
                                                    getValueFromEvent={val => val}
                                                /* getValueProps={e => e} */
                                                >
                                                    {/*  EditorHTML  */}

                                                    <ReactQuill className="shadow-sm"
                                                        theme="snow"
                                                        style={{
                                                            height: 200,
                                                            width: '98%',
                                                            marginTop: '1rem',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: 'white'
                                                        }}

                                                        modules={{
                                                            toolbar: [

                                                                /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                                             /*    [{ 'header': [4] }], */
                                                                ['bold', 'italic', 'underline'],
                                                                /*  [{ 'color': [] }, { 'background': [] }], */
                                                                [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                                                /* [{ 'align': ['center'] }], */
                                                                [{'script': 'sub'}, {'script': 'super'}],
                                                                ['clean']

                                                            ],
                                                        }}
                                                        formats={[
                                                            'header', 'font', 'size',
                                                            'script', 'sub', 'super',
                                                            'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                                            'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                                                        ]}

                                                    />
                                                </Form.Item>

                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </div>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add("")} block icon={<PlusOutlined />}>
                                            Ajouter un texte important
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>


                </div>
            </Badge.Ribbon>
        </div>
    )
}

export default ImageInputs