import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Modal, TablePaginationConfig, Table, notification, DatePicker, Input, ConfigProvider, Empty } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UpdateNewsModal from "../components/news/UpdateNewsModal";
import { NewsTable } from "../interfaces/NewsInterface";
import { ColumnsType } from 'antd/es/table';
import dayjs from "dayjs";
import { deleteNotification, editNotificationById, getHistoryNotification, getNotificationById } from "../services/notificaionService";


const ListNews = () => {
    const columns: ColumnsType<NewsTable> = [

        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (record: string) => {
                return (
                    <p>{dayjs(record).format('DD/MM/YYYY')}</p>
                )
            }
        },
        {
            title: "Titre de l'actualité",
            dataIndex: 'title',
            key: 'title',
        },

        {
            title: 'Actualité Fr',
            dataIndex: 'data',
            key: 'data',
            render: (record: { contentFr: string, contentAr: string }) => {
                return (
                    <p>{record.contentFr}</p>
                )
            }
        },
        {
            title: 'Actualité Ar',
            dataIndex: 'data',
            key: 'data',
            render: (record: { contentFr: string, contentAr: string }) => {
                return (
                    <p>{record.contentAr}</p>
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: { id: string }) => {
                return (
                    <div className='actions__category'>
                        <DeleteOutlined style={{ marginRight: "20px", color: 'red' }} onClick={() => deleteCategoryHandler(record)} />
                        <EditOutlined style={{ marginRight: "20px", color: 'green' }} onClick={() => editCategoryHandler(record)} />

                    </div>
                )
            }
        }
    ];

    const [news, setNews] = useState<any>()
    const [newsForm, setNewsForm] = useState({})
    const [newsId, setNewsid] = useState<string>("")
    const [newsTab, setNewsTab] = useState<any>([])
    const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false);
    const [isModalEditVisible, setIsModalEditVisible] = useState<boolean>(false);
    const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    })
    const [filtredNotif, setFiltrerdNotif] = useState<any>([])
    const [filterField, setFilterField] = useState({
        title: "",
        date: ""
    })

    useEffect(() => {
        /*  async function fetchAllNews() {
             const res = await getAllNews()
             if (res.data) setNewsTab(res.data)
             if (res.error) notification.error({
                 message: 'Erreur serveur interne !',
             });
         }
         fetchAllNews() */

        async function getNotifications(): Promise<void> {
            const res = await getHistoryNotification("news")
            if (res.data) {
                setFiltrerdNotif(res.data)
                setNewsTab(res.data)
            }
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }

        getNotifications()
    }, [])


    const deleteCategoryHandler = (record: { id: string }) => {
        setNewsid(record.id)
        setIsModalDeleteVisible(true);

    }

    const editCategoryHandler = async (record: { id: string }) => {
        setNewsid(record.id)
        setIsModalEditVisible(true);
        const res = await getNotificationById(record.id)
        if (res) {

            /* setUpdateAddressValues(res.data) */
            if (res.data) {
                setNewsForm(res.data)
                setNews(res.data)
                setIsModalEditVisible(true);
            }
            if (res.error) {
                notification.error({
                    message: 'Erreur interne, essayer plus tard!',
                });
            }

        }
    }

    /* Delete Modal */

    const handleDeleteOk = async (): Promise<void> => {
        setIsModalDeleteVisible(false);
        //  Delete API
        if (newsId) {
            const res = await deleteNotification(newsId)
            if (res.status === 200) window.location.reload()
            if (res.error) notification.error({
                message: 'Erreur serveur interne !',
            });
        }
    };

    const handleDeleteCancel = (): void => {
        setNewsid('')
        setNews(undefined)
        setNewsForm({})
        setIsModalDeleteVisible(false);

    };

    /* Edit Modal */

    const handleEditOk = async (): Promise<void> => {
        setIsModalEditVisible(false);

        const res = await editNotificationById(newsId, newsForm)
        if (res.status === 200) window.location.reload()
        if (res.error) notification.error({
            message: 'Erreur interne, essayer plus tard!',
        });
        // consume Update API here
    };

    const handleEditCancel = (): void => {
        setNewsid('')
        setNews(undefined)
        setNewsForm({})
        setIsModalEditVisible(false);
    };

    /* Get news data */
    const handleChangeNews = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>): void => {

        if (e.target.name === 'title') {
            setNewsForm({ ...newsForm, [e.target.name]: e.target.value })
        } else {
            setNewsForm({ ...newsForm, data: Object.assign(news.data, { [e.target.name]: e.target.value }) })
        }

    }

    const handleTableChange = (pagination: TablePaginationConfig): void => {

        setPaginationTable(pagination)
        /* Here pagination get the other data */
    };


    const handleChangeDatePicker = (val: any): void => {

        if (val) {
            setFilterField({ ...filterField, date: dayjs(val).format('DD/MM/YYYY') })
        } else {
            setFilterField({ ...filterField, date: "" })
        }

    }

    const handleChangeFilterTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {

        if (e.target.value) {
            setFilterField({ ...filterField, title: e.target.value.toLowerCase() })
        } else {
            setFilterField({ ...filterField, title: "" })
        }


    }

    /* Filter data */
    useEffect(() => {
        if (filterField) {
            if (filterField.title && filterField.date) {
                const filtredNotifData: any = newsTab.filter((notif: {title:string, createdAt: string}) => notif.title.toLowerCase().startsWith(filterField.title.toLowerCase()) && dayjs(notif.createdAt).format('DD/MM/YYYY') === filterField.date)
                    
                setFiltrerdNotif(filtredNotifData)
            }
            if (filterField.title && !filterField.date) {
                const filtredNotifData = newsTab.filter((notif: {title:string}) => notif.title.toLowerCase().startsWith(filterField.title.toLowerCase()))
                   
                setFiltrerdNotif(filtredNotifData)
            }
            if (!filterField.title && filterField.date) {
                const filtredNotifData = newsTab.filter((notif: {createdAt:string}) => dayjs(notif.createdAt).format('DD/MM/YYYY') === filterField.date)
                setFiltrerdNotif(filtredNotifData)
            }
            if (!filterField.title && !filterField.date) {
                setFiltrerdNotif(newsTab)
            }

        }
    }, [filterField])

    const { t } = useTranslation()

    return (
        <div className='news'>
            <div className='headerInfo'>
                <h2>{t('news.listNews')}</h2>
                <div className='filterFields'>
                    <DatePicker placeholder='Choisir une date' onChange={handleChangeDatePicker} />
                    <Input type='text' onChange={handleChangeFilterTitle} placeholder="Titre de l'actualité" />
                </div>
            </div>
            <ConfigProvider renderEmpty={() => <Empty description="Aucun résultat n’est trouvé"/>}>
            <Table<NewsTable> onChange={handleTableChange} pagination={paginationTable} columns={columns} dataSource={filtredNotif} />
            </ConfigProvider>
            <div className='deleteCategoryModal'>
                <Modal title="Supprimer l'actualité" visible={isModalDeleteVisible} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
                    <p>Voulez-vous vraiment supprimer cette actualité?</p>
                </Modal>
            </div>

            <UpdateNewsModal news={news} newsFrom={newsForm} handleChangeNews={handleChangeNews} handleEditCancel={handleEditCancel} handleEditOk={handleEditOk} isModalEditVisible={isModalEditVisible} />

        </div>
    )
}

export default ListNews