import axiosInstance from '../utils/axiosInstance'

export async function sendPushNotification(data: {
    title: string,
    data: { contentFr?: string, contentAr?: string, content?: string },
    type: string
}) {
    try {
        const res = await axiosInstance(`/Notification/createNotifcation`, {
            method: "POST",
            data
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getHistoryNotification(type: string) {
    try {
        const res = await axiosInstance(`/Notification/getNotifcations?type=${type}&limit=0&page=1`, {
            method: "GET",
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getNotificationById(id: string) {
    try {
        const res = await axiosInstance(`/Notification/getNotifcationById/${id}`, {
            method: "GET",
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function deleteNotification(id: string) {
    try {
        const res = await axiosInstance(`/Notification/deleteNotificationById/${id}`, {
            method: "DELETE",
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function editNotificationById(id: string, data: any) {
    try {
        const res = await axiosInstance(`/Notification/updateNotifcationById/${id}`, {
            method: "PATCH",
            data
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}