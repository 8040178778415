import { Button, Form, Input, notification } from "antd"
import TextArea from "rc-textarea"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { News } from "../interfaces/NewsInterface"
import '../components/news/news.css'
import { useNavigate } from "react-router"
import { sendPushNotification } from "../services/notificaionService"
const CreateNews = () => {

    const { t } = useTranslation()
    const [news, setNews] = useState<News>({
        title: '',
        contentFr: '',
        contentAr: ''
    })
    const navigate = useNavigate()
    /* Get news data onChange */
    const handleChangeNews = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>): void => {
        setNews({ ...news, [e.target.name]: e.target.value })
    }

    /*  Get news data onSubmit  */
    const onFinish = async (): Promise<void> => {

        const notif = {
            title: news.title,
            data: { contentFr: news.contentFr,  contentAr: news.contentAr,},
            type: "news"
        }
        const res = await sendPushNotification(notif)
        if (res.data) {
            window.location.pathname = "/news"
            /* notification.success({
                message: 'Actualité envoyée avec succès',
            }); */
        }
        if (res.error) {
            notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }

    };


    return (
        <div className='news'>
            <h2>{t('news.addNews')}</h2>

            <Form className='contentCategory createNewsForm' autoComplete="off" onFinish={onFinish}>

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='title' label='Titre'>
                    <Input name="title" onChange={handleChangeNews} />
                </Form.Item>

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='bodyFR' label={t('news.bodyFR')}>
                    <TextArea name="contentFr" onChange={handleChangeNews} />
                </Form.Item>

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='bodyAR' label={t('news.bodyAR')}>
                    <TextArea className="rtl" name="contentAr" onChange={handleChangeNews} />
                </Form.Item>

                <Form.Item className='btnSubmit__createCat'>
                    <Button type="primary" htmlType="submit">
                        Publier
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CreateNews