import { MinusCircleOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Form, Input, notification, Space, Badge } from "antd"
import Avatar from "antd/lib/avatar/avatar"
import { useEffect, useState } from "react"
import { AboutUsInterface } from "../interfaces/AboutUsInterface"
import { getAboutUs, updateAboutUs } from "../services/aboutusService"
import { uploadMedia } from "../services/postService"


const AboutUs = () => {
    const [aboutus, setAboutUs] = useState<AboutUsInterface>()
    const [updateData, setUpdateData] = useState<boolean>(true)

    useEffect(() => {
        async function fetchAboutUsData(): Promise<void> {
            const res = await getAboutUs()
            if (res.data) setAboutUs(res.data[0])
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }
        fetchAboutUsData()
    }, [])

    const handleFinish = async (data: AboutUsInterface): Promise<void> => {

        let formUploadMedia = new FormData()

        async function aboutusData(about: AboutUsInterface) {
            if (typeof about.supportLogo === "object") {
                formUploadMedia.append(`file`, about.supportLogo);
                const resLogo = await uploadMedia(formUploadMedia)
                if (resLogo) about.supportLogo = resLogo.data
            }

            for (const item of about.thanksFR) {
                if (typeof item.logoFR === "object") {
                    formUploadMedia.delete("file");
                    formUploadMedia.append(`file`, item.logoFR);
                    const res = await uploadMedia(formUploadMedia)

                    if (res) item.logoFR = res.data

                }

            }
            for (const item of about.thanksAR) {
                if (typeof item.logoAR === "object") {
                    formUploadMedia.delete("file");
                    formUploadMedia.append(`file`, item.logoAR);
                    const res = await uploadMedia(formUploadMedia)

                    if (res) item.logoAR = res.data
                }
            }
/* 
            about.linkInstagram = "" */

            const res = await updateAboutUs(about)
            if (res.status === 200) notification.success({
                message: 'Contenu enregistré avec succès',
            });
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });

            

        }
        aboutusData(data)
    }

    const handleDeleteImg = (section: string, index: number, key: string) => {
        /* 'thanks',fieldKey, "logoFR" */
        if (section === "thanksFR") {
            if (aboutus) {
                aboutus.thanksFR[index].logoFR = ""
                setUpdateData(prev => !prev)
            }
        }
        
        if (section === "thanksAR") {
            if (aboutus) {
                aboutus.thanksAR[index].logoAR = ""
                setUpdateData(prev => !prev)
            }
        }

        if (section === "support") {
            if (aboutus) {
                aboutus.supportLogo = ""
                setUpdateData(prev => !prev)
            }
        }
    }

    return (
        <div className='aboutus'>
            <h2>A propos de nous :</h2>

            {aboutus && <Form initialValues={aboutus} className='formAboutUs' onFinish={handleFinish}>

                <div className='creator'>
                    <h3>Les créateurs de l'application :</h3>
                    <Badge.Ribbon text="FR" color="red">

                        <Form.Item name='introductionCreatorFR' label="Introduction FR">
                            <Input type='text' />
                        </Form.Item>

                        <Form.List name="creatorFR">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (

                                        <Space className='posts' key={key} style={{ width: '99.5%', justifyContent: 'space-between', display: 'flex' }} align="baseline">
                                            <div className='aboutusContainer' >

                                                <div style={{ display: 'block' }} >
                                                    <Form.Item {...restField}
                                                        label={"Nom et prénom"}
                                                        name={[name, 'nameFR']}
                                                        fieldKey={[fieldKey, 'nameFR']}>
                                                        <Input type='text' />
                                                    </Form.Item>
                                                    <Form.Item {...restField}
                                                        label={"Poste"}
                                                        name={[name, 'posteFR']}
                                                        fieldKey={[fieldKey, 'posteFR']}>
                                                        <Input type='text' />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <MinusCircleOutlined className='removePosts' onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add({
                                            nameFR: '',
                                            posteFR: ''
                                        })} block icon={<PlusOutlined />}>
                                            Ajouter un nouveau créateur
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                    </Badge.Ribbon>
                    <Badge.Ribbon text="AR" color="green">

                        <Form.Item name='introductionCreatorAR' label="Introduction AR">
                            <Input className="rtl" type='text' />
                        </Form.Item>

                        <Form.List name="creatorAR">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (

                                        <Space className='posts' key={key} style={{ width: '99.5%', justifyContent: 'space-between', display: 'flex' }} align="baseline">
                                            <div className='aboutusContainer' >

                                                <div style={{ display: 'block' }} >
                                                    <Form.Item {...restField}
                                                        label={"Nom et prénom"}
                                                        name={[name, 'nameAR']}
                                                        fieldKey={[fieldKey, 'nameAR']}>
                                                        <Input className="rtl" type='text' />
                                                    </Form.Item>
                                                    <Form.Item {...restField}
                                                        label={"Poste"}
                                                        name={[name, 'posteAR']}
                                                        fieldKey={[fieldKey, 'posteAR']}>
                                                        <Input className="rtl" type='text' />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <MinusCircleOutlined className='removePosts' onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add({
                                            nameAR: '',
                                            posteAR: ''
                                        })} block icon={<PlusOutlined />}>
                                            Ajouter un nouveau créateur
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                    </Badge.Ribbon>
                </div>


                <div className='support'>
                    <h3>L'application est supportée par :</h3>

                    <div className="supportHeader">

                        <div className="imgAndDeleteBtnFlex">
                            <img onClick={() => handleDeleteImg('support', 0, "")} className="deleteBtn" alt='' src={require('../assets/img/delete.png').default} />
                            <Avatar style={{ marginBottom: '20px' }} src={aboutus.supportLogo ? aboutus.supportLogo : null} size={64}>Logo</Avatar>
                        </div>



                        <Form.Item name='supportLogo' getValueFromEvent={e => e.target.files[0]}
                            getValueProps={e => e}
                            label='Logo' >
                            <Input name='supportLogo' type='file' accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp" /* onChange={handleChangeImgFR} */ />
                        </Form.Item>
                        <Form.Item name='supportFb' label="Lien facebook">
                            <Input type='text' />
                        </Form.Item>
                    </div>

                    <Badge.Ribbon text="FR" color="red">



                        <Form.Item name='supportTitleFR' label="Titre FR">
                            <Input type='text' />
                        </Form.Item>
                        <Form.Item name='supportContentFR' label="Contenu FR">
                            <Input type='text' />
                        </Form.Item>
                        <Form.Item name='supportConfirmFR' label="Confirmé FR">
                            <Input type='text' />
                        </Form.Item>
                        <Form.Item name='supportConfirmNameFR' label="Confirmé par FR">
                            <Input type='text' />
                        </Form.Item>
                        

                        <Form.List name="supportersFR">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (

                                        <Space className='posts' key={key} style={{ width: '99.5%', justifyContent: 'space-between', display: 'flex' }} align="baseline">
                                            <div className='aboutusContainer' >

                                                <div style={{ display: 'block' }} >
                                                    <Form.Item {...restField}
                                                        label={"Nom et prénom"}
                                                        name={[name]}
                                                        fieldKey={[fieldKey]}>
                                                        <Input type='text' />
                                                    </Form.Item>


                                                </div>
                                            </div>
                                            <MinusCircleOutlined className='removePosts' onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add("")} block icon={<PlusOutlined />}>
                                            Ajouter un nouveau supporteur
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                    </Badge.Ribbon>
                    <Badge.Ribbon text="AR" color="green">

                        <Form.Item name='supportTitleAR' label="Titre AR">
                            <Input className="rtl" type='text' />
                        </Form.Item>
                        <Form.Item name='supportContentAR' label="Contenu AR">
                            <Input className="rtl" type='text' />
                        </Form.Item>
                        <Form.Item name='supportConfirmAR' label="Confirmé AR">
                            <Input type='text' />
                        </Form.Item>
                        <Form.Item name='supportConfirmNameAR' label="Confirmé par AR">
                            <Input type='text' />
                        </Form.Item>

                        <Form.List name="supportersAR">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (

                                        <Space className='posts' key={key} style={{ width: '99.5%', justifyContent: 'space-between', display: 'flex' }} align="baseline">
                                            <div className='aboutusContainer' >

                                                <div style={{ display: 'block' }} >
                                                    <Form.Item {...restField}
                                                        label={"Nom et prénom"}
                                                        name={[name]}
                                                        fieldKey={[fieldKey]}>
                                                        <Input className="rtl" type='text' />
                                                    </Form.Item>


                                                </div>
                                            </div>
                                            <MinusCircleOutlined className='removePosts' onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add("")} block icon={<PlusOutlined />}>
                                            Ajouter un nouveau supporteur
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                    </Badge.Ribbon>
                </div>


                <div className='thanks'>
                    <h3>Remerciement :</h3>
                    <Badge.Ribbon text="FR" color="red">

                        <Form.Item name='thanksTitleFR' label="Titre FR">
                            <Input type='text' />
                        </Form.Item>

                        <Form.List name="thanksFR">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (

                                        <Space className='posts' key={key} style={{ width: '99.5%', justifyContent: 'space-between', display: 'flex' }} align="baseline">
                                            <div className='aboutusContainer' >

                                                <div style={{ display: 'block' }} >


                                                    <div className="imgAndDeleteBtnFlex">
                                                        <img onClick={() => handleDeleteImg('thanksFR', fieldKey, "logoFR")} className="deleteBtn" alt='' src={require('../assets/img/delete.png').default} />
                                                        <Avatar style={{ marginBottom: '20px' }} src={aboutus.thanksFR[fieldKey] ? aboutus.thanksFR[fieldKey].logoFR : null} size={64}>Logo</Avatar>
                                                    </div>


                                                    <Form.Item getValueFromEvent={e => e.target.files[0]}
                                                        getValueProps={e => e} name={[name, 'logoFR']} {...restField}
                                                        fieldKey={[fieldKey, 'logoFR']} label='Upload Logo FR' >
                                                        <Input name='logoFR' type='file' accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp" /* onChange={handleChangeImgFR} */ />
                                                    </Form.Item>


                                                    <Form.Item {...restField}
                                                        label={"Nom FR"}
                                                        name={[name, 'thanksNameFR']}
                                                        fieldKey={[fieldKey, 'thanksNameFR']}>
                                                        <Input type='text' />
                                                    </Form.Item>


                                                </div>
                                            </div>
                                            <MinusCircleOutlined className='removePosts' onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add({
                                            logoFR: "",
                                            thanksNameFR: ""
                                        })} block icon={<PlusOutlined />}>
                                            Ajouter un nouveau apprécié
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="AR" color="green">
                        <Form.Item name='thanksTitleAR' label="Titre AR">
                            <Input className="rtl" type='text' />
                        </Form.Item>
                        <Form.List name="thanksAR">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (

                                        <Space className='posts' key={key} style={{ width: '99.5%', justifyContent: 'space-between', display: 'flex' }} align="baseline">
                                            <div className='aboutusContainer' >

                                                <div style={{ display: 'block' }} >

                                                    <div className="imgAndDeleteBtnFlex">
                                                        <img onClick={() => handleDeleteImg('thanksAR', fieldKey, "logoAR")} className="deleteBtn" alt='' src={require('../assets/img/delete.png').default} />
                                                        <Avatar style={{ marginBottom: '20px' }} src={aboutus.thanksAR[fieldKey] ? aboutus.thanksAR[fieldKey].logoAR : null} size={64}>Logo</Avatar>
                                                    </div>

                                                    <Form.Item getValueFromEvent={e => e.target.files[0]}
                                                        getValueProps={e => e} name={[name, 'logoAR']} {...restField}
                                                        fieldKey={[fieldKey, 'logoAR']} label='Upload Logo AR' >
                                                        <Input name='logoAR' type='file' accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp" /* onChange={handleChangeImgFR} */ />
                                                    </Form.Item>
                                                    <Form.Item {...restField}
                                                        label={"Nom AR"}
                                                        name={[name, 'thanksNameAR']}
                                                        fieldKey={[fieldKey, 'thanksNameAR']}>
                                                        <Input className="rtl" type='text' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <MinusCircleOutlined className='removePosts' onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add({
                                            logoAR: "",
                                            thanksNameAR: ""
                                        })} block icon={<PlusOutlined />}>
                                            Ajouter un nouveau apprécié
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Badge.Ribbon>
                </div>
                <Form.Item name='linkFb' label="Lien facebook">
                    <Input type='text' />
                </Form.Item>
                <Form.Item name='linkInstagram' label="Lien Instagram">
                    <Input type='text' />
                </Form.Item>
                <Form.Item name='linkYoutube' label="Lien Youtube">
                    <Input type='text' />
                </Form.Item>
                <Button className="aboutBtn" type='primary' htmlType="submit">
                    Enregistrer
                </Button>
            </Form>}
        </div>
    )
}

export default AboutUs

