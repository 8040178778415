
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Badge, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { PropsInterface } from '../../interfaces/PostInterface'


const VideoInputs: React.FC<PropsInterface> = ({ num, fieldKey, restField }) => {
    const { t } = useTranslation()


    return (

        <div className='containerPost'>
            <div className="infoContent__file">
                <Form.Item  {...restField} name={[num, 'file']}
                    fieldKey={[fieldKey, 'file']} label={t('contentCategory.inputs.video')} >
                    <Input name='video' placeholder='https://sexo-sante.tn/' />
                </Form.Item>
            </div>


            <Badge.Ribbon text="FR" color="red">
                <div className='intoContent__fr'>

                    <Form.Item {...restField}
                        name={[num, 'contentFR']}
                        fieldKey={[fieldKey, 'contentFR']}
                        getValueFromEvent={val => val}
                    /* getValueProps={e => e} */
                    >
                        {/*  EditorHTML  */}

                        <ReactQuill className="shadow-sm"
                            theme="snow"
                            style={{
                                height: 350,
                                width: '98%',
                                marginTop: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: 'white'
                            }}

                            modules={{
                                toolbar: [
                                    /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                    /* [{ 'header': [4] }], */
                             
                                    ['bold', 'italic', 'underline'],
                                    /*  [{ 'color': [] }, { 'background': [] }], */
                                    [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                   /*  [{ 'align': ['center'] }], */
                                    [{'script': 'sub'}, {'script': 'super'}],
                                    ['clean']

                                ],
                            }}
                            formats={[
                                'header', 'font', 'size',
                                'script', 'sub', 'super',
                                'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                            ]}

                        />
                    </Form.Item>


                    <b>Ajouter un texte important :</b>

                    <div className='importantTxt'>

                        <Form.List name={[num, "importantTxtFR"]}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'block' }} align="baseline">
                                            <div style={{ display: 'flex', alignItems: "baseline", justifyContent: 'space-between' }} >
                                                <Form.Item {...restField}
                                                    name={[name]}
                                                    fieldKey={[fieldKey]}
                                                    getValueFromEvent={val => val}
                                                /* getValueProps={e => e} */
                                                >
                                                    {/*  EditorHTML  */}

                                                    <ReactQuill className="shadow-sm"
                                                        theme="snow"
                                                        style={{
                                                            height: 200,
                                                            width: '98%',
                                                            marginTop: '1rem',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: 'white'
                                                        }}

                                                        modules={{
                                                            toolbar: [
                                                                /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                                                /* [{ 'header': [4] }], */
                                                                ['bold', 'italic', 'underline'],
                                                                /*  [{ 'color': [] }, { 'background': [] }], */
                                                                [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                                                /* [{ 'align': ['center'] }], */
                                                                [{'script': 'sub'}, {'script': 'super'}],
                                                                ['clean']

                                                            ],
                                                        }}
                                                        formats={[
                                                            'header', 'font', 'size',
                                                            'script', 'sub', 'super',
                                                            'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                                            'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                                                        ]}

                                                    />
                                                </Form.Item>

                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </div>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add("")} block icon={<PlusOutlined />}>
                                            Ajouter un texte important
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>

                </div>
            </Badge.Ribbon>



            <Badge.Ribbon text="AR" color="green">
                <div className='intoContent__fr intoContent__ar'>
                    <Form.Item {...restField}
                        name={[num, 'contentAR']}
                        fieldKey={[fieldKey, 'contentAR']}
                        getValueFromEvent={val => val}
                    /* getValueProps={e => e} */
                    >
                        {/*  EditorHTML  */}

                        <ReactQuill className="shadow-sm"
                            theme="snow"
                            style={{
                                height: 350,
                                width: '98%',
                                marginTop: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: 'white'
                            }}

                            modules={{
                                toolbar: [
                                    /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                    /* [{ 'header': [4] }], */
                                    ['bold', 'italic', 'underline'],
                                    /*  [{ 'color': [] }, { 'background': [] }], */
                                    [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                    /* [{ 'align': ['center'] }], */
                                    [{'script': 'sub'}, {'script': 'super'}],
                                    ['clean']


                                ],
                            }}
                            formats={[
                                'header', 'font', 'size',
                                'script', 'sub', 'super',
                                'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                            ]}

                        />
                    </Form.Item>

                    <b>Ajouter un texte important :</b>

                    <div className='importantTxt'>
                        <Form.List name={[num, "importantTxtAR"]}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'block' }} align="baseline">
                                            <div style={{ display: 'flex', alignItems: "baseline", justifyContent: 'space-between' }} >
                                                <Form.Item {...restField}
                                                    name={[name]}
                                                    fieldKey={[fieldKey]}
                                                    getValueFromEvent={val => val}
                                                /* getValueProps={e => e} */
                                                >
                                                    {/*  EditorHTML  */}

                                                    <ReactQuill className="shadow-sm"
                                                        theme="snow"
                                                        style={{
                                                            height: 200,
                                                            width: '98%',
                                                            marginTop: '1rem',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: 'white'
                                                        }}

                                                        modules={{
                                                            toolbar: [
                                                                /* [{ 'header': [1, 2, 3, 4, 5] }], */
                                                                /* [{ 'header': [4] }], */
                                                                ['bold', 'italic', 'underline'],
                                                                /*  [{ 'color': [] }, { 'background': [] }], */
                                                                [/* { 'list': 'ordered' }, */ { 'list': 'bullet' }],
                                                                /* [{ 'align': ['center'] }], */
                                                                [{'script': 'sub'}, {'script': 'super'}],
                                                                ['clean']


                                                            ],
                                                        }}
                                                        formats={[
                                                            'header', 'font', 'size',
                                                            'script', 'sub', 'super',
                                                            'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                                                            'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                                                        ]}

                                                    />
                                                </Form.Item>

                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </div>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add("")} block icon={<PlusOutlined />}>
                                            Ajouter un texte important
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>


                </div>
            </Badge.Ribbon>
        </div>
    )

}

export default VideoInputs