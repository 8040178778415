import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode"

const { REACT_APP_ENDPOINT } = process.env
let authToken = localStorage.getItem('token') ? localStorage.getItem("token") : null

const axiosInstance = axios.create({
    baseURL: REACT_APP_ENDPOINT,
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
})


axiosInstance.interceptors.request.use(async (req: any) => {
    authToken = localStorage.getItem('token') ? localStorage.getItem("token") : null
    req.headers.Authorization = `Bearer ${authToken}`

    if (authToken) {
        const user: any = jwt_decode(authToken)
        // Check if the token expired
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

        if (!isExpired) return req

        /* Token Expired */
            localStorage.removeItem('token')
            window.location.pathname = '/'
    
    }

    return req
})

export default axiosInstance



/* const AxiosInstance = axios.create({
    baseURL: REACT_APP_ENDPOINT,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
})

AxiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        // unauthen error 401
        let originalConfig = error.config;
        if (error.response.status === 401 || error.response.status === 403) {
            // get token from storage
            const token = await localStorage.getItem('token');
            if (token) {
                // remove token
                localStorage.removeItem('token');
                // redirect to login
                window.location.href = '/login'
            }
            // continue send currently request
            return AxiosInstance(originalConfig);
        }
        return Promise.reject(error.response.data);
    },
); */