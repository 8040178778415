import { CloseOutlined } from "@ant-design/icons"
import { Button, Input, Modal, Form } from "antd"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { PropsUpdateAddressModal } from "../../interfaces/AddressInterface"


const UpdateAddressModal: React.FC<PropsUpdateAddressModal> = ({ adressForm, adress, handleChangeAdress, handleEditCancel, handleEditOk, isModalEditVisible }) => {
    const { t } = useTranslation()

    const form = useRef<any>()


    return (
        <div className='editCategoryModal'>

            {adressForm && <Modal closeIcon={<CloseOutlined onClick={handleEditCancel} />} footer={[
                <Button key="back" onClick={handleEditCancel}>
                    Annuler
                </Button>,
                <Button key="submit" type="primary" onClick={handleEditOk}>
                    Modifier
                </Button>

            ]} title="Modifier l'adresse" visible={isModalEditVisible} >
                <Form ref={form} initialValues={adress} className='contentCategory' autoComplete="off">

                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Champ obligatoire',
                        },
                    ]}  name='regionFr' label={t('adress.regionFR')} >
                        <Input name="regionFr" onChange={handleChangeAdress} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Champ obligatoire',
                        },
                    ]}  name='regionAr' label={t('adress.regionAR')} >
                        <Input className="rtl" name="regionAr" onChange={handleChangeAdress} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Champ obligatoire',
                        },
                    ]}  name='locationFr' label={t('adress.adressFR')}>
                        <Input name="locationFr" onChange={handleChangeAdress} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Champ obligatoire',
                        },
                    ]}  name='locationAr' label={t('adress.adressAR')}>
                        <Input className="rtl" name="locationAr" onChange={handleChangeAdress} />
                    </Form.Item>
                    <Form.Item rules={[
                        
                        {
                            pattern: new RegExp(/^\d*\.?\d*$/g),
                            message: "Ce champ doit contenir seulement des chiffres séparés avec point (ex: 10 / 10.533) "
                        },
                    ]} name='longitude' label={"Longitude"}>
                        <Input name="longitude" onChange={handleChangeAdress} />
                    </Form.Item>
                    <Form.Item rules={[
                      
                        {
                            pattern: new RegExp(/^\d*\.?\d*$/g),
                            message: "Ce champ doit contenir seulement des chiffres séparés avec point (ex: 10 / 10.533) "
                        },
                    ]} name='latitude' label={"Latitude"}>
                        <Input name="latitude" onChange={handleChangeAdress} />
                    </Form.Item>

                    <Form.Item name='phone' label={t('adress.phoneNumber')}>
                        <Input name="phone" onChange={handleChangeAdress} />
                    </Form.Item>


                </Form>
            </Modal>}

        </div>
    )
}

export default UpdateAddressModal