
import { Table, Modal, TablePaginationConfig, notification, ConfigProvider } from 'antd';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ColumnsType } from 'antd/es/table';
import UpdateAddressModal from "../components/adress/UpdateAddressModal";
import { addressEndpoint, ListAdressInterface } from "../interfaces/AddressInterface";
import { deleteAddress, getAddressById, getListAddress, updateAddressById } from "../services/addressService";
import Empty from 'antd/es/empty';


const ListAdress = () => {

  const columns: ColumnsType<ListAdressInterface> = [

    {
      title: 'Région',
      key: 'regionFr',
      render: (record: { regionFr: string, regionAr: string }) => {
        return (
          <div >
            <p>{record.regionFr} / {record.regionAr}</p>
          </div>
        )
      }
    },
    {
      title: 'Adresse',
      key: 'locationFr',
      render: (record: { locationFr: string, locationAr: string }) => {
        return (
          <div >
            <p>{record.locationFr} / {record.locationAr}</p>
          </div>
        )
      }

    },
    {
      title: 'Numéro de téléphone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      key: 'longitude',
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      key: 'latitude',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: { id: string }) => {
        return (
          <div className='actions__category'>
            <DeleteOutlined style={{ marginRight: "20px", color: 'red' }} onClick={() => deleteCategoryHandler(record)} />
            <EditOutlined style={{ marginRight: "20px", color: 'green' }} onClick={() => editCategoryHandler(record)} />

          </div>
        )
      }
    }
  ];


  const [adress, setAdress] = useState<addressEndpoint>()
  const [adressForm, setAdressForm] = useState<addressEndpoint>()
  const [, setUpdateAddressValues] = useState<ListAdressInterface>()
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState<boolean>(false);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  })
  const [listeAddress, setListeAddress] = useState<ListAdressInterface[]>([])
  const [addressId, setAddressId] = useState<string>()

  useEffect(() => {
    async function getlistAddress(): Promise<void> {
      const res = await getListAddress()
      if (res.data) setListeAddress(res.data)
      if (res.error) {
        notification.error({
          message: 'Erreur interne, essayer plus tard!',
        });
      }
    }

    getlistAddress()
  }, [])

  const deleteCategoryHandler = (record: { id: string }): void => {
    setAddressId(record.id)
    setIsModalDeleteVisible(true);

  }
  const editCategoryHandler = async (record: { id: string }) : Promise<void> => {
    setAddressId(record.id)
    const res = await getAddressById(record.id)
    if (res) {

      setUpdateAddressValues(res.data)
      if (res.data) {
        setAdressForm(res.data)
        setAdress(res.data)
        setIsModalEditVisible(true);
      }
      if (res.error) {
        notification.error({
          message: 'Erreur interne, essayer plus tard!',
        });
      }

    }
  }


  /* Delete Modal */

  const handleDeleteOk = async () : Promise<void>=> {
    setIsModalDeleteVisible(false);
    let res;
    if (addressId) res = await deleteAddress(addressId)
    if (res?.status === 200) window.location.reload()
    if (res?.error) notification.error({
      message: 'Erreur interne, essayer plus tard!',
    });

    // consume delete API
  };

  const handleDeleteCancel = (): void => {
    setIsModalDeleteVisible(false);

  };

  /* Edit Modal */

  const handleEditOk = async (): Promise<void> => {
    let res;
    setIsModalEditVisible(false);

    if (addressId && adressForm) res = await updateAddressById(adressForm, addressId)
    if (res?.status === 200) window.location.reload()
    if (res?.error) notification.error({
      message: 'Erreur interne, essayer plus tard!',
    });

  };

  const handleEditCancel = (): void => {
    setAddressId("")
    setAdress({})
    setAdressForm(undefined)
    setUpdateAddressValues(undefined)
    setIsModalEditVisible(false);
  };

  /* Get address data */
  const handleChangeAdress = (e: React.ChangeEvent<HTMLInputElement>): void => {
   
    setAdressForm({ ...adressForm, [e.target.name]: e.target.value })
  }

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    setPaginationTable(pagination)
    /* Here pagination get the other data */
  };


  return (
    <div className='listAddress'>
      <h2>Liste des adresses</h2>
      <ConfigProvider renderEmpty={() => <Empty description="Aucun résultat n’est trouvé"/>}>
      <Table<ListAdressInterface> onChange={handleTableChange} pagination={paginationTable} columns={columns} dataSource={listeAddress} />
      </ConfigProvider>
      <div className='deleteCategoryModal'>
        <Modal title="Supprimer l'adresse" visible={isModalDeleteVisible} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
          <p>Voulez-vous vraiment supprimer cette adresse?</p>
        </Modal>
      </div>

      <UpdateAddressModal adressForm={adressForm} adress={adress} handleChangeAdress={handleChangeAdress} handleEditCancel={handleEditCancel} handleEditOk={handleEditOk} isModalEditVisible={isModalEditVisible} />

    </div>

  )
}

export default ListAdress