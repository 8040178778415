
import axiosInstance from '../utils/axiosInstance'

export async function uploadMedia(data: FormData) {
    try {
        const res = await axiosInstance(`/media/createMedia`, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data,
        })
        return { data: res.data.fileS3.Location }

    } catch (err: any) {
        return { error: err.response }
    }

}

export async function createPost(data: any) {

    try {
        const res = await axiosInstance(`/content/createContent`, {
            method: "POST",
            data
        })
        return { status: res }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getPosts() {

    try {
        const res = await axiosInstance(`/content/getContents`, {
            method: "GET",
        })


        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getPostById(id: string) {

    try {
        const res = await axiosInstance(`/content/getContentById/${id}`, {
            method: "GET",
        })

        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function updateContent(id: string, data:any) {

    try {
        const res = await axiosInstance(`/content/updateContentById/${id}`, {
            method: "PATCH",
            data
        })

        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function deleteContent(id: string) {

    try {
        const res = await axiosInstance(`/content/deleteContentById/${id}`, {
            method: "DELETE",
        })

        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}