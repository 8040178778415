

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Cell,
} from "recharts";


const RatingChart = ({ statistics }: any) => {


    const CustomizedLabel = ({ x, y, width, height, fill, value }: any) => {

        const fireOffset = value.toString().length < 5;
        const offset = fireOffset ? -30 : 5;

        return (
            <text
                x={x + width - offset} y={y + height - 5}
                dy={-20}
                fontSize='20'
                fontWeight='bold'
                fontFamily='sans-serif'
                fill={fill}
                textAnchor="middle">{value}</text>
        )

    }


    const renderCustomAxisTick = ({ x, y, payload }: any) => {
        let path;

        const rating1 = <svg x={x - 62} y={y - 32} xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
            <g id="Groupe_9216" data-name="Groupe 9216" transform="translate(-251 -397)">
                <circle id="Ellipse_458" data-name="Ellipse 458" cx="21.5" cy="21.5" r="21.5" transform="translate(261 412)" fill="#eae3ff" />
                <g id="Groupe_9202" data-name="Groupe 9202" transform="translate(7 28)">
                    <rect id="Rectangle_5070" data-name="Rectangle 5070" width="68" height="68" transform="translate(244 369)" fill="none" />
                    <path id="Ellipse_450" data-name="Ellipse 450" d="M21.026-6A27.026,27.026,0,1,1-6,21.026,27.057,27.057,0,0,1,21.026-6Zm0,50.873A23.847,23.847,0,1,0-2.82,21.026,23.874,23.874,0,0,0,21.026,44.873Z" transform="translate(256.889 382.059)" fill="#8468d1" />
                    <path id="Tracé_10090" data-name="Tracé 10090" d="M104.046,155.547a1.589,1.589,0,0,1-1.378-.794,11.136,11.136,0,0,0-19.281,0,1.59,1.59,0,0,1-2.753-1.591,14.315,14.315,0,0,1,24.786,0,1.59,1.59,0,0,1-1.375,2.386Z" transform="translate(184.888 261.846)" fill="#8468d1" />
                    <ellipse id="Ellipse_451" data-name="Ellipse 451" cx="2.5" cy="3" rx="2.5" ry="3" transform="translate(266 395)" fill="#8468d1" />
                    <ellipse id="Ellipse_452" data-name="Ellipse 452" cx="2.5" cy="3" rx="2.5" ry="3" transform="translate(285 395)" fill="#8468d1" />
                </g>
            </g>
        </svg>
        const rating2 = <svg x={x - 62} y={y - 32} xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
            <g id="Groupe_9215" data-name="Groupe 9215" transform="translate(-154 -397)">
                <circle id="Ellipse_457" data-name="Ellipse 457" cx="21.5" cy="21.5" r="21.5" transform="translate(165 412)" fill="#fae4b7" />
                <g id="smiley-meh" transform="translate(154 397)">
                    <rect id="Rectangle_5071" data-name="Rectangle 5071" width="68" height="68" fill="none" />
                    <path id="Ellipse_453" data-name="Ellipse 453" d="M21.094-6A27.094,27.094,0,1,1-6,21.094,27.124,27.124,0,0,1,21.094-6Zm0,51A23.906,23.906,0,1,0-2.812,21.094,23.933,23.933,0,0,0,21.094,45Z" transform="translate(12.906 12.906)" fill="#ffab02" />
                    <path id="Ligne_180" data-name="Ligne 180" d="M16.844-2.812H-4.406A1.594,1.594,0,0,1-6-4.406,1.594,1.594,0,0,1-4.406-6h21.25a1.594,1.594,0,0,1,1.594,1.594A1.594,1.594,0,0,1,16.844-2.812Z" transform="translate(27.781 46.906)" fill="#ffab02" />
                    <circle id="Ellipse_454" data-name="Ellipse 454" cx="2.5" cy="2.5" r="2.5" transform="translate(22 26)" fill="#ffab02" />
                    <circle id="Ellipse_455" data-name="Ellipse 455" cx="2.5" cy="2.5" r="2.5" transform="translate(41 26)" fill="#ffab02" />
                </g>
            </g>
        </svg>

        const rating3 = <svg x={x - 62} y={y - 32} xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
            <g id="Groupe_9214" data-name="Groupe 9214" transform="translate(-57 -397)">
                <circle id="Ellipse_456" data-name="Ellipse 456" cx="21.5" cy="21.5" r="21.5" transform="translate(67 412)" fill="#ffe0e2" />
                <g id="Groupe_9203" data-name="Groupe 9203" transform="translate(-187 28)">
                    <rect id="Rectangle_5070" data-name="Rectangle 5070" width="68" height="68" transform="translate(244 369)" fill="none" />
                    <path id="Ellipse_450" data-name="Ellipse 450" d="M21.026-6A27.026,27.026,0,1,1-6,21.026,27.057,27.057,0,0,1,21.026-6Zm0,50.873A23.847,23.847,0,1,0-2.82,21.026,23.874,23.874,0,0,0,21.026,44.873Z" transform="translate(256.889 382.059)" fill="#c02" />
                    <path id="Tracé_10090" data-name="Tracé 10090" d="M104.046,155.547a1.589,1.589,0,0,1-1.378-.794,11.136,11.136,0,0,0-19.281,0,1.59,1.59,0,0,1-2.753-1.591,14.315,14.315,0,0,1,24.786,0,1.59,1.59,0,0,1-1.375,2.386Z" transform="translate(370.943 563.404) rotate(180)" fill="#c02" />
                </g>
                <path id="Tracé_10094" data-name="Tracé 10094" d="M43,15.6a4.188,4.188,0,0,0-2.7.9,4.388,4.388,0,0,0-2.7-.9,4.481,4.481,0,0,0-4.5,4.5c0,3.6,6,7.7,6.7,8.1l.5.3.5-.3c.7-.4,6.7-4.2,6.7-8.2A4.461,4.461,0,0,0,43,15.6ZM40.3,26.2c-2.2-1.6-5.3-4.3-5.3-6.1a2.476,2.476,0,0,1,2.5-2.5,2.334,2.334,0,0,1,2,1l.8,1,.8-1a2.61,2.61,0,0,1,2-1,2.476,2.476,0,0,1,2.5,2.5C45.5,22.1,42.5,24.7,40.3,26.2Z" transform="translate(61 402.4)" fill="#bb262c" />
                <path id="Tracé_10095" data-name="Tracé 10095" d="M20.2,28.6l.5-.3c.7-.4,6.7-4.2,6.7-8.2a4.481,4.481,0,0,0-4.5-4.5,4.188,4.188,0,0,0-2.7.9,4.388,4.388,0,0,0-2.7-.9A4.481,4.481,0,0,0,13,20.1c0,3.6,6,7.7,6.7,8.1Zm-5.3-8.5a2.476,2.476,0,0,1,2.5-2.5,2.334,2.334,0,0,1,2,1l.8,1,.8-1a2.61,2.61,0,0,1,2-1,2.476,2.476,0,0,1,2.5,2.5c0,2-3,4.6-5.2,6.1C18,24.6,14.9,21.9,14.9,20.1Z" transform="translate(61 402.4)" fill="#bb262c" />
            </g>
        </svg>


        switch (payload.value.toString()) {
            case "good":
                path = rating3
                break;
            case "normal":
                path = rating2
                break;
            case "bad":
                path = rating1
                break;
            default:
                path = rating1
        }

        return path
    }

    return (
        <>
            {statistics && <div className='rating__chart'>
                <h2>Evaluation de l'application</h2>
                
                <BarChart
                    width={350}
                    height={300}
                    layout="vertical"

                    data={statistics.opinionCount}
                >
                    <XAxis type="number" hide />
                    <YAxis tick={renderCustomAxisTick} dataKey="opinion" type="category" />

                    <Bar
                        dataKey="opinioncount"
                        fill='#8884d8'
                        barSize={60}
                        label={CustomizedLabel}
                    >
                        {
                            statistics.opinionCount.map((entry:any, index: number) => (<Cell key={`cell-${index}`} fill={entry.opinion === "bad" ? '#8468D1' : entry.opinion === "normal" ? '#FFAB02' : "#CC0022"} />)

                            )
                        }
                    </Bar>


                </BarChart>
              
            </div>}
        </>

    )
}

export default RatingChart