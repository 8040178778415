import { CloseOutlined } from "@ant-design/icons"
import { Button, Input, Modal, Form, Select, notification, Avatar } from "antd"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { CategoryInformationInterface, PropsCatModal } from "../../../interfaces/categoryInterface";
import { getMainCategroy } from "../../../services/categoryService";


const { Option } = Select;

const UpdateCatModal: React.FC<PropsCatModal> = ({iconPreview, updateCatValues, handleChangeCategory, handleEditCancel, handleEditOk, isModalEditVisible, handleChangeSelect }) => {
    const { t } = useTranslation()
    const [listParentCategory, setListParentCategory] = useState<CategoryInformationInterface['category'][]>([])

useEffect(() => {
    async function getMainCategories(): Promise<void> {
      let res = await getMainCategroy()
      if (res.data) setListParentCategory(res.data)
    
      if (res.error) {
        notification.error({
          message: 'Erreur interne, essayer plus tard!',
        });
      }
    }
    getMainCategories()
  }, [])

    return (
        <div className='editCategoryModal'>
            {updateCatValues && <Modal closeIcon={<CloseOutlined onClick={handleEditCancel} />} footer={[
                <Button key="back" onClick={handleEditCancel}>
                    Annuler
                </Button>,
                <Button key="submit" type="primary" onClick={handleEditOk}>
                    Modifier
                </Button>
            ]} title="Modifier la catégorie" visible={isModalEditVisible} >
                <Form initialValues={!updateCatValues.data.parentId ? {
                    titleFr: updateCatValues.data.titleFr,
                    titleAr: updateCatValues.data.titleAr,
                    primaryColor: updateCatValues.data.primaryColor,
                    secondaryColor: updateCatValues.data.secondaryColor,
                } : {
                    titleFr: updateCatValues.data.titleFr,
                    titleAr: updateCatValues.data.titleAr,
                    parentId: updateCatValues.data.parentId.id,
                    primaryColor: updateCatValues.data.primaryColor,
                    secondaryColor: updateCatValues.data.secondaryColor,
                }} className='contentCategory updateModalCategory' autoComplete="off">


                    {!updateCatValues.data.parentId &&
                        <>
                            <Avatar className='avatarCategory' shape="square" size={80} src={iconPreview || updateCatValues.data.icon} />
                            <Form.Item name='icon' label="Image">
                                <Input name="icon" onChange={handleChangeCategory} type='file' accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp" />
                            </Form.Item>
                        </>}



                    <Form.Item name='titleFr' label={t('createCategory.inputs.titleFR')} >
                        <Input name="titleFr" onChange={handleChangeCategory} />
                    </Form.Item>
                    <Form.Item name='titleAr' label={t('createCategory.inputs.titleAR')}>
                        <Input className="rtl" name="titleAr" onChange={handleChangeCategory} />
                    </Form.Item>

                    {updateCatValues.data.parentId && <Form.Item name='parentId'  label={t('createCategory.inputs.parentCategory')}>
                        {listParentCategory.length !== 0 && <Select defaultValue='' onChange={handleChangeSelect}>

                            {listParentCategory.map((parent: any) => {
                                return (
                                    <Option value={parent.id}>{parent.titleFr}</Option>
                                )
                            })}
                        </Select>}

                    </Form.Item>}


                    {!updateCatValues.data.parentId &&
                        <>
                            <Form.Item name='primaryColor' className='paletteColor__Category' label={t('createCategory.inputs.primaryColor')}>
                                <Input name="primaryColor" onChange={handleChangeCategory} type='color' />
                            </Form.Item>
                            <Form.Item name='secondaryColor' className='paletteColor__Category' label={t('createCategory.inputs.secColor')}>
                                <Input name="secondaryColor" onChange={handleChangeCategory} type='color' />
                            </Form.Item>
                        </>}


                </Form>
            </Modal>}

        </div>
    )
}

export default UpdateCatModal