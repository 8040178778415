import './sidebar.css'
import { Drawer, Menu } from 'antd';
import { BellOutlined, DiffOutlined, EnvironmentOutlined, FileUnknownOutlined, HomeOutlined, MedicineBoxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import { PropsSideBar } from '../../../interfaces/LayoutInterface';
import { Fragment, useEffect, useState } from 'react';

const { SubMenu } = Menu;

const SideBar: React.FC<PropsSideBar> = ({ visibleDrawer, setVisibleDrawer }) => {

    const location = useLocation();
    const [selectedSideBarKey, setSelectedSideBarKey] = useState<undefined | string[]>(undefined)
    const [selectedSideBarSub, setSelectedSideBarSub] = useState<undefined | string[]>(undefined)
    const onClose = (): void => {
        setVisibleDrawer(false)
    };



    useEffect(() => {
        if (location.pathname === "/") {
            setSelectedSideBarKey(["1"])
            setSelectedSideBarSub([""])
        }
        if (location.pathname === "/category") {
            setSelectedSideBarKey(["3"])
            setSelectedSideBarSub(["sub1"])
        }
        if (location.pathname === "/create-category") {
            setSelectedSideBarKey(["4"])
            setSelectedSideBarSub(["sub1"])
        }
        if (location.pathname === "/post") {
            setSelectedSideBarKey(["9"])
            setSelectedSideBarSub(["sub5"])
        }
        if (location.pathname === "/create-post") {
            setSelectedSideBarKey(["1"])
            setSelectedSideBarSub(["sub5"])
        }
        if (location.pathname === "/address") {
            setSelectedSideBarKey(["5"])
            setSelectedSideBarSub(["sub2"])
        }
        if (location.pathname === "/create-address") {
            setSelectedSideBarKey(["6"])
            setSelectedSideBarSub(["sub2"])
        }
        if (location.pathname === "/notification") {
            setSelectedSideBarKey(["7"])
            setSelectedSideBarSub(["sub3", "sub4"])
        }
        if (location.pathname === "/send-notification") {
            setSelectedSideBarKey(["8"])
            setSelectedSideBarSub(["sub3", "sub4"])
        }
        if (location.pathname === "/news") {
            setSelectedSideBarKey(["12"])
            setSelectedSideBarSub(["sub6", "sub3"])
        }
        if (location.pathname === "/add-news") {
            setSelectedSideBarKey(["13"])
            setSelectedSideBarSub(["sub6", "sub3"])
        }
        if (location.pathname === "/quiz") {
            setSelectedSideBarKey(["2"])
            setSelectedSideBarSub([""])
        }
        if (location.pathname === "/about-us") {
            setSelectedSideBarKey(["11"])
            setSelectedSideBarSub([""])
        }
        if (location.pathname.includes("/subCategory") ) {
            setSelectedSideBarKey(["4"])
            setSelectedSideBarSub(["sub1"])
        }
        if (location.pathname.includes("/post")) {
            setSelectedSideBarKey(["9"])
            setSelectedSideBarSub(["sub5"])
        }
        if (location.pathname === "/account") {
            setSelectedSideBarKey(["1"])
            setSelectedSideBarSub([""])
        }
        if (location.pathname === "/create-quiz") {
            setSelectedSideBarKey(["2"])
            setSelectedSideBarSub([""])
        }
    }, [location.pathname])


    return (
        <div className='sidebar'>
            {selectedSideBarKey !== undefined && selectedSideBarSub !== undefined ? <Fragment>
                <Menu
                    /* onClick={handleClick} */
                    style={{ width: 261 }}
                    defaultSelectedKeys={selectedSideBarKey}
                    defaultOpenKeys={selectedSideBarSub}
                    className="menuSidebar"
                    mode="inline"

                >
                    { console.log("chenn", selectedSideBarKey)}
                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        <Link className='sidebarLinks' to={'/'}>Accueil</Link>
                    </Menu.Item>

                    <SubMenu key="sub1" icon={<MedicineBoxOutlined />} title="Catégories">
                        <Menu.Item key="3"><Link className='sidebarLinks' to={'/category'}>Liste des catégories</Link></Menu.Item>
                        <Menu.Item key="4"><Link className='sidebarLinks' to={'/create-category'}>Ajouter une catégorie</Link></Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub5" icon={<DiffOutlined />} title="Publications">
                        <Menu.Item key="9"><Link className='sidebarLinks' to={'/post'}>Liste des publications</Link></Menu.Item>
                        <Menu.Item key="10"><Link className='sidebarLinks' to={'/create-post'}>Ajouter un post</Link></Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub2" icon={<EnvironmentOutlined />} title="Adresses">
                        <Menu.Item key="5"><Link className='sidebarLinks' to={'/address'}>Liste des adresses</Link></Menu.Item>
                        <Menu.Item key="6"><Link className='sidebarLinks' to={'/create-address'}>Ajouter une adresse</Link></Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub3" icon={<BellOutlined />} title="Notifications">
                        <SubMenu key="sub4" title="Information">
                            <Menu.Item key="7"><Link className='sidebarLinks' to={'/notification'}>Historique des informations</Link></Menu.Item>
                            <Menu.Item key="8"><Link className='sidebarLinks' to={'/send-notification'}>Envoyer une information</Link></Menu.Item>
                        </SubMenu>

                        <SubMenu key="sub6" title="Actualité">
                            <Menu.Item key="12"><Link className='sidebarLinks' to={'/news'}>Liste des actualités</Link></Menu.Item>
                            <Menu.Item key="13"><Link className='sidebarLinks' to={'/add-news'}>Ajouter une actualité</Link></Menu.Item>
                        </SubMenu>
                    </SubMenu>

                    <Menu.Item key="2" icon={<QuestionCircleOutlined />}>
                        <Link className='sidebarLinks' to={'/quiz'}>Quiz</Link>
                    </Menu.Item>
                    <Menu.Item key="11" icon={<FileUnknownOutlined />}>
                        <Link className='sidebarLinks' to={'/about-us'}>A propos de nous</Link>
                    </Menu.Item>

                </Menu>

                {/*  Drawer for mobile */}
                <Drawer

                    placement={'left'}
                    closable={false}
                    onClose={onClose}
                    visible={visibleDrawer}
                    key={'left'}
                    width={'261px'}
                    className='navbarDrawer'
                >
                    <div className='logoSidebar'>
                        <img alt='' className='logoImg' src={require('../../../assets/img/logo.svg').default} />
                    </div>
                    <Menu
                        /* onClick={handleClick} */
                        style={{ width: 261.4 }}
                        /* defaultSelectedKeys={['1']} */
                        className="menuSidebarDrawer"
                        mode="inline"
                        selectedKeys={["2"]}
                    >
                        <Menu.Item key="1" icon={<HomeOutlined />}>
                            <Link className='sidebarLinks' to={'/'}>Accueil</Link>
                        </Menu.Item>

                        <SubMenu key="sub1" icon={<MedicineBoxOutlined />} title="Catégories">
                            <Menu.Item key="3"><Link className='sidebarLinks' to={'/category'}>Liste des catégories</Link></Menu.Item>
                            <Menu.Item key="4"><Link className='sidebarLinks' to={'/create-category'}>Ajouter une catégorie</Link></Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub5" icon={<DiffOutlined />} title="Publications">
                            <Menu.Item key="9"><Link className='sidebarLinks' to={'/post'}>Liste des publications</Link></Menu.Item>
                            <Menu.Item key="10"><Link className='sidebarLinks' to={'/create-post'}>Ajouter un post</Link></Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" icon={<EnvironmentOutlined />} title="Adresses">
                            <Menu.Item key="5"><Link className='sidebarLinks' to={'/address'}>Liste des adresses</Link></Menu.Item>
                            <Menu.Item key="6"><Link className='sidebarLinks' to={'/create-address'}>Ajouter une adresse</Link></Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" icon={<BellOutlined />} title="Notifications">
                            <SubMenu key="sub4" title="Information">
                                <Menu.Item key="7"><Link className='sidebarLinks' to={'/notification'}>Historique des informations</Link></Menu.Item>
                                <Menu.Item key="8"><Link className='sidebarLinks' to={'/send-notification'}>Envoyer une information</Link></Menu.Item>
                            </SubMenu>

                            <SubMenu key="sub6" title="Actualité">
                                <Menu.Item key="12"><Link className='sidebarLinks' to={'/news'}>Liste des actualités</Link></Menu.Item>
                                <Menu.Item key="13"><Link className='sidebarLinks' to={'/add-news'}>Ajouter une actualité</Link></Menu.Item>
                            </SubMenu>
                        </SubMenu>

                        <Menu.Item key="2" icon={<QuestionCircleOutlined />}>
                            <Link className='sidebarLinks' to={'/quiz'}>Quiz</Link>
                        </Menu.Item>
                        <Menu.Item key="11" icon={<FileUnknownOutlined />}>
                            <Link className='sidebarLinks' to={'/about-us'}>A propos de nous</Link>
                        </Menu.Item>
                    </Menu>
                </Drawer>
            </Fragment> : null}

        </div>
    );
}

export default SideBar;