import '../components/home/home.css'
import Header from '../components/home/Header'
import QuizChart from '../components/home/QuizChart'
import SexeChart from '../components/home/SexeChart'
import RatingChart from '../components/home/RatingChart'
import CalendarChart from '../components/home/CalendarChart'
import { useEffect, useState } from 'react'
import { getListQuiz, getQuizstatistics, getStatistics } from '../services/homeService'
import { notification } from 'antd'
import { QuizChartInterface, statisticsInterface } from '../interfaces/HomeInterface'
import { ListQuizInterface } from '../interfaces/QuizInterface'

const Home = () => {

    const [statistics, setStatistics] = useState<statisticsInterface>()
    const [quizStatistics, setQuizStatistics] = useState<QuizChartInterface>()
    const [listQuiz, setListQuiz] = useState<ListQuizInterface['ListQuizInterface'] | []>([])
    const [formatQuizStatistics, setFormatQuizStatistics] = useState<QuizChartInterface['resultCount'] | []>([])

    useEffect(() => {
        async function fetchStatistics(): Promise<void> {
            const res = await getStatistics()
            if (res.data) setStatistics(res.data)
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }

        async function fetchQuizstatistics(id: string): Promise<void> {
            const res = await getQuizstatistics(id)
            if (res.data) setQuizStatistics(res.data)
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }

        async function fetchListQuiz(): Promise<void> {
            const res = await getListQuiz()
            if (res.data) {
                setListQuiz(res.data)
                const listLength = res.data.length
                fetchQuizstatistics(res.data[listLength-1].id)
            }
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }

        fetchStatistics()
        fetchListQuiz()
    }, [])


    useEffect(() => {
        async function formattedQuizData(): Promise<void> {
            if (quizStatistics) {
                const quizStat: any = quizStatistics['resultCount']
                await quizStat.data.forEach((quiz: any) => {
                  /*   quiz.name = `Note ${quiz.name}` */
                    quiz['name'] = `Note ${quiz.note}`
                })
                console.log('quizStatquizStat,', quizStat)

                setFormatQuizStatistics(quizStat.data)
            }
        }
        formattedQuizData()
    }, [quizStatistics])


    return (
        <div className='home'>
            {statistics && <>
                <Header quizStatistics={quizStatistics} statistics={statistics} />
                <div className='homeContainerCharts' >
                    {formatQuizStatistics && <QuizChart setQuizStatistics={setQuizStatistics} formatQuizStatistics={formatQuizStatistics} quizStatistics={quizStatistics} listQuiz={listQuiz} />}
                    <RatingChart statistics={statistics} />
                </div>

                <SexeChart statistics={statistics} />
                {/* <CalendarChart statistics={statistics} /> */}
            </>}

        </div>
    )

}

export default Home