import { QuizData } from '../interfaces/QuizInterface';
import axiosInstance from '../utils/axiosInstance'


export async function updateQuiz(data: QuizData, id: string) {
    if (data.quiz === undefined) data.quiz = []
    const res = await axiosInstance(`/quiz/updateQuiz/${id}`, {
        method: "POST",
        data: { questions: data.quiz }
    })
    return res
}
export async function getQuiz() {

    const res = await axiosInstance(`/quiz/getQuiz`, {
        method: "GET"
    })
    return res
}
export async function createQuiz(data: QuizData) {
    if (data.quiz === undefined) data.quiz = []
    const res = await axiosInstance(`/quiz/createQuiz`, {
        method: "POST",
        data: { questions: data.quiz }
    })
    return res
}