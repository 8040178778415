import { AboutUsInterface } from '../interfaces/AboutUsInterface'
import axiosInstance from '../utils/axiosInstance'

export async function createAboutUs(data: AboutUsInterface) {

    try {
        const res = await axiosInstance(`/about-us/createAboutUs`, {
            method: "POST",
            data,
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}

export async function updateAboutUs(data: AboutUsInterface) {

    try {
        const res = await axiosInstance(`/about-us/updateAboutUs`, {
            method: "PATCH",
            data,
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}

export async function getAboutUs() {

    try {
        const res = await axiosInstance(`/about-us/getAboutUs`, {
            method: "GET"
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}