import { StarFilled } from "@ant-design/icons"

const Header = ({ statistics, quizStatistics }: any) => {
    return (
        <>
            {statistics && <div className='home__header'>
                <div className='totalUsers'>
                    <div className='iconHeader'>
                        <img  alt="" src={require('../../assets/img/users.png').default} />
                    </div>
                    <div className='content__header'>
                        <div><b>{statistics.usersCount}</b></div>
                        Total des utilisateurs
                    </div>
                </div>
                <div className='totalUsers totalQuiz'>
                    <div className='iconHeader'>
                        <img  alt="" className='starHeader' src={require('../../assets/img/quiz.png').default} />
                    </div>
                    {quizStatistics && <div className='content__header'>
                        <div><b>{quizStatistics.quizResponcesCount}</b></div>
                        Total des réponses sur les quiz
                    </div>}
                </div>
                <div className='totalUsers totalQuiz ratingApplication'>
                    <div className='iconHeader'>
                        <StarFilled className='starHeader' />
                    </div>
                    <div className='content__header'>
                        <div><b>{statistics.moyOpinion} / 5 <StarFilled /></b></div>
                        {statistics.opinionTotal} total des évaluations
                    </div>
                </div>
            </div>}
        </>

    )
}
export default Header