
import { Table, Modal, TablePaginationConfig, ConfigProvider, Empty } from 'antd';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ColumnsType } from 'antd/es/table';
import UpdateCatModal from "../components/category/listCategory/UpdateCatModal";
import { deleteMainCategroy, EditSubCategory, getSubCategoryById } from "../services/categoryService";
import { notification } from "antd";
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { Category, CategoryInformationInterface, CreateCategoryInterface, PaginationTable } from "../interfaces/categoryInterface";




const ListSubCategory = () => {


    const [category, setCategory] = useState<CategoryInformationInterface['category']>({})
    const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false);
    const [isModalEditVisible, setIsModalEditVisible] = useState<boolean>(false);
    const { id } = useParams()
    const [paginationTable, setPaginationTable] = useState<PaginationTable>({
        current: 1,
        pageSize: 10,
    })
    const navigate = useNavigate()
    const [listParentCategory, setListParentCategory] = useState([])
    const [categoryId, setCategoryId] = useState<string>()
    const [updateCatValues, setUpdateCatValues] = useState<any>()
    const [iconPreview, setIconPreview] = useState<string>()
    const [, setIconUpdate] = useState<string>()

    const deleteCategoryHandler = (record: CategoryInformationInterface['category']) => {
        setCategoryId(record.id)
        setIsModalDeleteVisible(true);

    }
    const editCategoryHandler = async (record: CategoryInformationInterface['category']) => {
        setCategoryId(record.id)
        const subCatData = await listParentCategory.filter((item: CategoryInformationInterface['category']) => item.id === record.id)
        setUpdateCatValues({ data: subCatData[0] })
        setCategory(subCatData[0])
        setIsModalEditVisible(true);
    }

    const columns: ColumnsType<Category> = [

        {
            title: 'Catégories FR',
            dataIndex: 'titleFr',
            key: 'titleFr',
        },
        {
            title: 'Catégories AR',
            dataIndex: 'titleAr',
            key: 'titleAr',
        },
        {
            title: 'Date de création',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (record: string) => {
                return (
                    <p>{dayjs(record).format('DD/MM/YYYY')}</p>
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: CategoryInformationInterface['category']) => {
                return (
                    <div className='actions__category'>
                        <DeleteOutlined style={{ marginRight: "20px", color: 'red' }} onClick={() => deleteCategoryHandler(record)} />
                        <EditOutlined style={{ marginRight: "20px", color: 'green' }} onClick={() => editCategoryHandler(record)} />

                    </div>
                )
            }
        },
    ];

    /* Delete Modal */
    const handleDeleteOk = async (): Promise<void> => {
        let res;

        if (categoryId) res = await deleteMainCategroy(categoryId)
        if (res?.status) window.location.reload();
        if (res?.error) {
            notification.error({
                message: "Erreur Interne, Essayer plus tard",
            });
        }
    };

    const handleDeleteCancel = (): void => {
        setIsModalDeleteVisible(false);

    };

    /* Edit Modal */

    const handleEditOk = async (): Promise<void> => {
        setIsModalEditVisible(false);


        if (updateCatValues.data.parentId) {

            if (category?.titleAr && category?.titleFr && category?.parentId && category?.id) {
                let res;
                const data: CreateCategoryInterface['createSubApi'] = {
                    titleAr: category?.titleAr,
                    titleFr: category?.titleFr,
                    parentId: category?.parentId.id || category?.parentId,
                }

                res = await EditSubCategory(data, category?.id)
                if (res.status === 200) window.location.reload()
                if (res.error) {
                    notification.error({
                        message: 'Erreur interne, essayer plus tard!',
                    });
                }
            }
        }


    };

    const handleEditCancel = (): void => {
        setCategoryId("")
        setUpdateCatValues("")
        setCategory({})
        setIconPreview("")
        setIconUpdate("")
        setIsModalEditVisible(false);
    };


    useEffect(() => {
        async function getSubCategories(): Promise<void> {
            let res;
            if (id) res = await getSubCategoryById(id)
            if (res) {
                if (res.data) {
                    if (res.data.length === 0) navigate('/category')
                    setListParentCategory(res.data)
                }
                if (res.error) {
                    notification.error({
                        message: 'Erreur interne, essayer plus tard!',
                    });
                }
            }
        }
        getSubCategories()
    }, [])


    /* Get category data */
    const handleChangeCategory = (e: any) => {

        if (e.target.name === "icon") {
            setCategory({ ...category, [e.target.name]: e.target.files[0] })
            setIconPreview(URL.createObjectURL(e.target.files[0]))
            setIconUpdate(e.target.files[0])
        } else {
            setCategory({ ...category, [e.target.name]: e.target.value })
        }
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        const page = {
            current: Number(pagination.current),
            pageSize: Number(pagination.pageSize)
        }
        setPaginationTable(page)
        /* Here pagination get the other data */
    };

    const handleChangeSelect = (value: string): void => {

        setCategory({ ...category, parentId: value })
    }

    return (
        <div className='listSubCategory'>
            {listParentCategory && <>


                <ArrowLeftOutlined onClick={() => navigate(-1)} className='arrowBack' />

                <h2>Liste des sous catégories :</h2>
                <ConfigProvider renderEmpty={() => <Empty description="Aucun résultat n’est trouvé" />}>
                    <Table<Category> onChange={handleTableChange} pagination={paginationTable} columns={columns} dataSource={listParentCategory} />
                </ConfigProvider>
                <div className='deleteCategoryModal'>
                    <Modal title="Supprimer la catégorie" visible={isModalDeleteVisible} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
                        <p>Voulez-vous vraiment supprimer cette catégorie?</p>
                        <p><b>Note:</b> Si vous effacez cette catégorie toutes les autres sous catégories seront effacez définitivement</p>
                    </Modal>
                </div>

                <UpdateCatModal listParentCategory={listParentCategory} iconPreview={iconPreview} updateCatValues={updateCatValues} handleChangeSelect={handleChangeSelect} handleChangeCategory={handleChangeCategory} handleEditCancel={handleEditCancel} handleEditOk={handleEditOk} isModalEditVisible={isModalEditVisible} />
            </>}

        </div>

    )
}

export default ListSubCategory