import { addressEndpoint } from '../interfaces/AddressInterface';
import axiosInstance from '../utils/axiosInstance'



export async function createAddress(data: addressEndpoint) {

    try {
        const res = await axiosInstance(`/address/createAddress`, {
            method: "POST",
            data,
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getListAddress() {
    try {
        const res = await axiosInstance(`/address/getAddresses`, {
            method: "GET",
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function deleteAddress(id: string) {
    try {
        const res = await axiosInstance(`/address/deleteAddressById/${id}`, {
            method: "DELETE",
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function getAddressById(id: string) {
    try {
        const res = await axiosInstance(`/address/getAddressById/${id}`, {
            method: "GET",
        })
        return { data: res.data }

    } catch (err: any) {
        return { error: err.response }
    }

}
export async function  updateAddressById(data: addressEndpoint, id: string) {
    try {
        const res = await axiosInstance(`/address/updateAddressById/${id}`, {
            method: "PATCH",
            data
        })
        return { status: res.status }

    } catch (err: any) {
        return { error: err.response }
    }

}