import { Button, Form } from 'antd'
import { useEffect, useState } from "react"
import CategoryInformation from '../components/category/createCategory/CategoryInformation'
import { createCategory, createSubCategory, getMainCategroy } from '../services/categoryService'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CategoryInformationInterface, CreateCategoryInterface } from '../interfaces/categoryInterface'


const CreateCategory = () => {
  
    const [category, setCategory] = useState<CategoryInformationInterface['category']>({
        secondaryColor: '#000000', primaryColor: "#000000"
    })
    const [showData, setShowData] = useState<boolean>(true)
    const [listParentCategory, setListParentCategory] = useState<CategoryInformationInterface['category'][]>([])

    let navigate = useNavigate()


    useEffect(() => {
  
        async function getMainCategories(): Promise<void> {
            let res = await getMainCategroy()
            if(res.data) setListParentCategory(res.data)
            if(res.error){
                notification.error({
                    message: 'Erreur interne, essayer plus tard!',
                });
            }
        }
        getMainCategories()

    }, [])


    /* Get category data */
    const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement> ): void => {
        if (e.target.name === "icon") {
            setCategory({ ...category, [e.target.name]: e.target.files })
        } else {
            setCategory({ ...category, [e.target.name]: e.target.value })
        }
    }

    const handleChangeSelect = (value: string): void => {

        setCategory({ ...category, parentId: value })
        if (!value) setShowData(true)
        if (value) setShowData(false)
    }

    /*  Get category data  */
    const onFinish = async (): Promise<void> => {

        console.log("categorycategory", category)

        let res;

        /* Category main */
        if (showData) {
            let formDataCategory = new FormData();
            if (category?.icon && category?.titleAr && category?.titleFr && category?.primaryColor && category?.secondaryColor) {
                formDataCategory.append(`titleAr`, category?.titleAr);
                formDataCategory.append(`titleFr`, category?.titleFr);
                formDataCategory.append(`icon`, category?.icon[0]);
                formDataCategory.append(`primaryColor`, category?.primaryColor);
                formDataCategory.append(`secondaryColor`, category?.secondaryColor);
            }
            res = await createCategory(formDataCategory)
        }

        /* Sub category */
        if (!showData) {
            if (category?.titleAr && category?.titleFr && category?.parentId) {
                const data: CreateCategoryInterface['createSubApi'] = {
                    titleAr: category?.titleAr,
                    titleFr: category?.titleFr,
                    parentId: category?.parentId,
                }
                res = await createSubCategory(data)
            }
        }

        /* Handle API Errors */
        if (res) {
            if (res.error === 500) {
                notification.error({
                    message: 'Erreur Interne !',
                });
            }
            if (res.error === 404) {
                notification.error({
                    message: "Catégorie père n'est pas trouvée !",
                });
            }
            if (res.status === 201) {
                window.location.pathname = "/category"
               /*  navigate('/category')
                notification.success({
                    message: 'Catégorie Ajoutée avec succès',
                }); */
            }
        }

    };

    return (
        <div className='createCategory'>
            <Form className='contentCategory' autoComplete="off" initialValues={{secondaryColor: '#000000', primaryColor: "#000000"}} onFinish={onFinish}>

                <CategoryInformation listParentCategory={listParentCategory} showData={showData} handleChangeSelect={handleChangeSelect} handleChangeCategory={handleChangeCategory} />

                <Form.Item className='btnSubmit__createCat'>
                    <Button type="primary" htmlType="submit">
                        Publier
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
export default CreateCategory