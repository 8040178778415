import { Fragment, useEffect, useRef, useState } from "react"
import { Button, Form, FormInstance, notification, Radio, RadioChangeEvent, Select, Space } from 'antd'
import { CaretDownOutlined, CaretUpOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import VideoInputs from "../components/post/VideoInputs";
import { createPost, uploadMedia } from "../services/postService";
import { useNavigate } from "react-router-dom";
import ImageInputs from "../components/post/ImageInputs";
import { getSubCategory } from "../services/categoryService";
import { PostInterfaceData } from "../interfaces/PostInterface";

const { Option } = Select;

const CreatePost = () => {
    const [option, setOption] = useState<{ type: string } | {}>({})
    const formRef = useRef<FormInstance>(null)
    const [selectOptions, setSelectOptions] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        async function fetchSubCategory(): Promise<void> {
            const res = await getSubCategory()
            if (res.data) setSelectOptions(res.data)
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }
        fetchSubCategory()
    }, [])

    const handleFinishPost = async (e: PostInterfaceData): Promise<void> => {
        const data = e.posts
        let formDataContent = new FormData()

        async function processArray(data: any) {
            for (const item of data) {
                if (typeof item.fileFR === "object") {
                    formDataContent.delete("file");

                    formDataContent.append(`file`, item.fileFR);
                    const res = await uploadMedia(formDataContent)

                    if (res) item.fileFR = res.data


                }
                if (typeof item.fileAR === "object") {
                    formDataContent.delete("file");

                    formDataContent.append(`file`, item.fileAR);
                    const res = await uploadMedia(formDataContent)

                    if (res) item.fileAR = res.data


                }
            }

            const content = {
                data: {
                    content: data
                },
                category: e.category
            }

            const res = await createPost(content)
            if (res.status?.status === 201) {
               
                window.location.pathname = "/post"
                notification.success({
                    message: 'Publication Ajoutée avec succès',
                });
            }
            if (res.error) notification.error({
                message: 'Erreur interne, essayer plus tard!',
            });
        }

        processArray(data)

    }

    const handleOptionsChange = (e: React.ChangeEvent<HTMLInputElement> | RadioChangeEvent, name: string, key: number) => {
        setOption({ ...option, [name]: e.target.value })

        /* on change type (video,image) clear values */
        if (formRef.current) {
            const data = formRef.current.getFieldsValue()

            if (data.posts[key]) {
                data.posts[key].fileFR = ""
                data.posts[key].fileAR = ""
                data.posts[key].contentAR = ""
                data.posts[key].contentFR = ""
                data.posts[key].importantTxtAR = []
                data.posts[key].importantTxtFR = []
            }

            formRef.current.setFieldsValue(data);
        }

    }

    return (
        <div className='createPost'>
            {/* <h2>Ajouter le contenu du catégorie :</h2> */}

            <Form className='formCreatePost' ref={formRef} onFinish={handleFinishPost} autoComplete="off" >

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Champ obligatoire',
                    },
                ]} name='category' label="Sous catégorie">
                    <Select style={{ width: '100%' }} >
                        {selectOptions && selectOptions.map((option: any) => {
                            return <Option value={option.id}>{option.parentId.titleFr} : {option.titleFr}</Option>
                        })}

                    </Select>
                </Form.Item>

                <Form.List initialValue={[{
                    contentAR: "",
                    contentFR: "",
                    fileFR: "",
                    fileAR: "",
                    importantTxtAR: [],
                    importantTxtFR: [],
                    type: "video"
                }]} name="posts">
                    {(fields, { add, remove, move }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (

                                <Space className='posts' key={key} style={{ width: '99.5%', justifyContent: 'space-between', display: 'flex' }} align="baseline">
                                    <div className='postContainer' >
                                {console.log('fieldKey', fieldKey)}
                                        <div className="moveArrows__container">
                                            <CaretUpOutlined className="arrowMove" onClick={() => move(name, name - 1)} />
                                            <CaretDownOutlined className="arrowMove" onClick={() => move(name, name + 1)} />
                                        </div>

                                        <div className='infoContent__options'>

                                            <Form.Item {...restField}
                                                name={[name, 'type']}
                                                fieldKey={[fieldKey, 'type']}>
                                                <Radio.Group defaultValue='video'>
                                                    <Radio onChange={(e) => handleOptionsChange(e, `options${name}`, name)} name={`options${name}`} value="video">Video</Radio>
                                                    <Radio onChange={(e) => handleOptionsChange(e, `options${name}`, name)} name={`options${name}`} value="image">Image</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>

                                        <div style={{ display: 'block' }} >

                                            {formRef.current &&
                                                <Fragment>
                                                    {formRef.current.getFieldValue('posts')[name].type === 'video' && <VideoInputs restField={restField} num={name} fieldKey={fieldKey} />}
                                                    {formRef.current.getFieldValue('posts')[name].type === 'image' && <ImageInputs restField={restField} num={name} fieldKey={fieldKey} />}
                                                </Fragment>}

                                        </div>
                                    </div>
                                    <MinusCircleOutlined className='removePosts' onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add({
                                    contentAR: "",
                                    contentFR: "",
                                    fileFR: "",
                                    fileAR: "",
                                    importantTxtAR: [],
                                    importantTxtFR: [],
                                    type: "video"
                                })} block icon={<PlusOutlined />}>
                                    Ajouter un nouveau contenu
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Button type='primary' htmlType="submit">
                    Ajouter un post
                </Button>

            </Form>

        </div>
    )
}

export default CreatePost