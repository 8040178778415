import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';
import { BrowserRouter } from "react-router-dom";
import './i18n/config';
import 'react-quill/dist/quill.snow.css';

import dayjs from 'dayjs'
import { ConfigProvider } from 'antd';
import fr_FR from "antd/lib/locale/fr_FR";
import 'dayjs/locale/fr'

import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  days : ["Lun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ConfigProvider  locale={fr_FR}>
      <App />
      </ConfigProvider>
    </BrowserRouter>
    
  </React.StrictMode>,
  document.getElementById('root')
);
