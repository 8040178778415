import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    
} from "recharts";

const SexeChart = ({ statistics }: any) => {

    
    return (
        <>
            {statistics && <div className="chart__HF">
            <h2>Les utilisateurs de l'application selon sexe</h2>
            
                <BarChart width={600} height={350} data={statistics.sexeCount}>
                    <XAxis dataKey="sexe" stroke="#8884d8" />
                    <YAxis />
                    <Tooltip wrapperStyle={{ width: '30%', backgroundColor: '#ccc' }} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <Bar dataKey="sexecount" name='nombre' fill="#BAA6CA" barSize={100} />
                </BarChart>
            </div>}
        </>
    )
}

export default SexeChart